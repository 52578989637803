import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import {
  QuestionCharteResultat,
  QuestionsCharte,
} from "../../interfaces/questionCharte.interface";

const initialState: QuestionsCharte = {
  tQuestion: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "questionsCharte",
  initialState,
  reducers: {
    questionCharteSucces: (
      state,
      { payload }: PayloadAction<[QuestionCharteResultat]>
    ) => {
      state.tQuestion = payload;
      state.sErreur = "";
    },
    questionCharteErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { questionCharteSucces, questionCharteErreur } = slice.actions;
export const selectQuestionCharte = (state: Store) =>
  state.questionsCharte.tQuestion as [QuestionCharteResultat];
export const selectStoreErreur = (state: Store) =>
  state.questionsCharte.sErreur;

export default slice.reducer;
