import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";

const initialState = {
  tDiscussion: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "discussion",
  initialState,
  reducers: {
    discussionSucces: (state, { payload }) => {
      state.tDiscussion = payload;
      state.sErreur = "";
    },
    discussionErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { discussionSucces, discussionErreur } = slice.actions;
export const selectDiscussion = (state: Store) => state.discussion.tDiscussion;
export const selectStoreErreur = (state: Store) => state.discussion.sErreur;

export default slice.reducer;
