import React from "react";
import styled from "styled-components";

import { Popup } from "semantic-ui-react";

const Conteneur = styled.div`
    display: flex;
    height: 25px;
    padding: 5px;
    border-radius: 10px;
    & div {
        height: 100%;
    }
    font-size: 12px;
    vertical-align: middle:
    line-height: 25px;
    text-align: center;
    font-style: bold;
`;
const ConteneurPour = styled.div`
background-color: green;
overflow: hidden;
`;
const ConteneurContre = styled.div`
background-color: red;
overflow: hidden;
`;
const ConteneurTotal = styled.div`
background-color: lightgrey;
overflow: hidden;
`;
const Decision = styled.div`
text-align: center;
`;

interface Props {
  htmlClientPour: any;
  htmlClientContre: any;
  htmlClientEnAttente: any;
  percentApprouve: number;
  nbApprouve: number;
  percentRefuse: number;
  nbRefuse: number;
  iTotal: number;
}

const Progress = ({
    htmlClientPour,
    htmlClientContre,
    htmlClientEnAttente,
    percentApprouve,
    nbApprouve,
    percentRefuse,
    nbRefuse,
    iTotal
}: Props) => {
    return (
        <>
        <Conteneur>
            <Popup
                trigger={<ConteneurPour style={{ width: `${percentApprouve}%` }} />}
                content={<>
                    <div>
                        <b>{nbApprouve}</b> {nbApprouve > 1 ? "structures ont" : "structure a"} voté pour.
                    </div>
                    <div>
                        Cela représente <b>{Math.round(percentApprouve * 100) / 100}%</b> des voix.
                    </div>
                    {htmlClientPour}
                </>}
            />
            <Popup
                trigger={<ConteneurTotal style={{ width: `${Math.round((100 - percentApprouve - percentRefuse) * 100) / 100}%` }} />}
                content={<>
                    <div>
                        <b>{iTotal - nbApprouve - nbRefuse}</b> vote{iTotal - nbApprouve - nbRefuse > 1 ? 's' : ''} en attente{iTotal - nbApprouve - nbRefuse > 1 ? 's' : ''}.
                    </div>
                    <div>
                        Cela représente <b>{Math.round((100 - percentApprouve - percentRefuse) * 100) / 100}%</b>.
                    </div>
                    {htmlClientEnAttente}
                </>}
            />
            <Popup
                trigger={<ConteneurContre style={{ width: `${percentRefuse}%` }} />}
                content={<>
                    <div>
                        <b>{nbRefuse}</b> {nbRefuse > 1 ? "structures ont" : "structure a"} voté contre.
                    </div>
                    <div>
                        Cela représente <b>{Math.round(percentRefuse * 100) / 100}%</b> des voix.
                    </div>
                    {htmlClientContre}
                </>}
            />
        </Conteneur>
        {Math.round((100 - percentApprouve - percentRefuse) * 100) / 100 !== 100 ? (<Decision>
            {(percentApprouve < 50 && percentRefuse < 50) && 'Tendance : '}
            <span style={{color: percentApprouve-percentRefuse < 0 ? "red" : "green"}}>
                {percentApprouve-percentRefuse < 0 ? (percentApprouve < 50 && percentRefuse < 50) ? "CONTRE" : "Refusé" : (percentApprouve < 50 && percentRefuse < 50) ? "POUR" : "Validé"}
            </span>
        </Decision>) : ""}
        </>
    )
};

export default Progress;
