import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import axios from "axios";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { H1 } from "../../core/layout/view/title.elements";
import { Card, CardTitle } from "../../core/layout/view/card.elements";

import DiscussionList from "./elements/List";

const DiscussionView: FunctionComponent = () => {
  const [discussions, setDiscussions] = useState<any[]>([]);

  useEffect(() => {
    const listDiscussions = async () => {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_GRAPHQL_URL}/discussions`
      );
      setDiscussions(response.data.tProjet);
    };
    listDiscussions();
  }, []);

  return (
    <AnimatedView title="Discussion">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <H1 style={{ marginTop: "40px" }}>Discussions</H1>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="flex-col"
            >
              <Card>
                <CardTitle>Liste des discussions</CardTitle>

                <DiscussionList discussions={discussions} events={[]} />
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default DiscussionView;
