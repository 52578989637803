import React, { FunctionComponent, useState } from "react";
import { sha512 } from "js-sha512";
import { useDispatch, useSelector } from "react-redux";

import { connexion } from "../../../../redux/utilisateur/actions/connexion.action";
import { selectStoreErreur } from "../../../../redux/utilisateur/utilisateur.slice";

import {
  Form,
  Field,
  Input,
  CommandField,
} from "../../../core/layout/form/form.elements";
import PasswordInput from "../../../core/layout/form/password.element";
import { SubmitButton } from "../../../core/layout/button/button.elements";
import { ErreurNotice } from "../../../core/layout/notice/notice.elements";

const ConnexionForm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [sIdentifiant, setIdentifiant] = useState<string>("");
  const [sMotDePasse, setMotDePasse] = useState<string>("");
  const [sErreur, setErreur] = useState<string>("");
  const sStoreErreur = useSelector(selectStoreErreur);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();

    if (isValidForm()) {
      dispatch(connexion(sIdentifiant, sha512(sMotDePasse)));
    }
  };

  const isValidForm = (): boolean => {
    if (sIdentifiant.length === 0) {
      setErreur("Veuillez saisir votre identifiant");
      return false;
    }

    if (sMotDePasse.length === 0) {
      setErreur("Veuillez saisir votre mot de passe");
      return false;
    }

    return true;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Field>
        <Input
          type="text"
          id="sIdentifiant"
          placeholder="Identifiant"
          value={sIdentifiant}
          onChange={(evt) => setIdentifiant(evt.target.value)}
        />
      </Field>

      <Field>
        <PasswordInput
          type="password"
          id="sMotDePasse"
          placeholder="Mot de passe"
          value={sMotDePasse}
          onChange={(evt: any) => setMotDePasse(evt.target.value)}
        />
      </Field>

      <CommandField>
        <SubmitButton type="submit">Connexion</SubmitButton>
      </CommandField>

      {sErreur && <ErreurNotice>{sErreur}</ErreurNotice>}
      {sStoreErreur && <ErreurNotice>{sStoreErreur}</ErreurNotice>}
    </Form>
  );
};

export default ConnexionForm;
