import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";

import { useSelector } from "react-redux";
import axios from "axios";

import { Loader, Popup, Icon } from "semantic-ui-react";

import * as Table from "../../../../core/layout/view/table.elements";
import Confirm from "../../../../core/layout/view/confirm.elements";
import {
  ButtonAdd,
  ButtonDelete,
  ButtonEdit,
} from "../../../../core/layout/button/button.elements";

import ProjectForm from "../Form";
import Progress from "../Progress";

import { selectUtilisateur } from "../../../../../redux/utilisateur/utilisateur.slice";
import { Projet } from "../../../../../interfaces/projet.interface";

interface Props {
  type: number;
}

interface Reponse {
  projet: Projet;
}

const ProjectsList: FunctionComponent<Props> = ({ type }: Props) => {
  const [addProjectModal, setAddProjectModal] = useState<boolean>(false);
  const [editProjectModal, setEditProjectModal] = useState<boolean>(false);
  const [deleteProjectModal, setDeleteProjectModal] = useState<boolean>(false);
  const [project, setProject] = useState<any>({});
  const [projectsLoaded, setProjectsLoaded] = useState<any>(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [structure, setStructure] = useState<any>({});
  const [structures, setStructures] = useState<any>({});
  const utilisateur = useSelector(selectUtilisateur);
  const [errors, setErrors] = useState<any>({});
  const isAdmin = utilisateur.identite.iRole === 4;
  const isDirection = utilisateur.identite.iRole === 3;
  const selection = projects && projects.filter((p) => p.iType === type);

  const listProjets = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projets`
    );
    setProjects(response.data.tProjet);
    setProjectsLoaded(true);
  }, []);

  const listStructure = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/structures`
    );
    setStructures(response.data.tStructure);
    if (utilisateur.identite.iFKStructure) {
      setStructure(
        response.data.tStructure.filter(
          (s: any) =>
            parseInt(s.iPKStructure, 10) === utilisateur.identite.iFKStructure,
          10
        )[0]
      );
    }
  }, [utilisateur.identite.iFKStructure]);

  useEffect(() => {
    listProjets();
    listStructure();
  }, [listProjets, listStructure]);

  const onBtnAddClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddProjectModal(true);
    setEditProjectModal(false);
    setDeleteProjectModal(false);
  };

  const onBtnEditClick = (evt: any, project: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddProjectModal(false);
    setEditProjectModal(true);
    setDeleteProjectModal(false);
    setProject(project);
  };

  const onBtnDeleteClick = (evt: any, project: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddProjectModal(false);
    setEditProjectModal(false);
    setDeleteProjectModal(true);
    setProject(project);
  };

  const onCloseProjectAddForm = () => {
    setAddProjectModal(false);
    setEditProjectModal(false);
    setDeleteProjectModal(false);
    setProject(null);
  };

  const onCloseProjectEditForm = () => {
    setAddProjectModal(false);
    setEditProjectModal(false);
    setDeleteProjectModal(false);
    setProject(null);
  };

  const onCloseProjectDeleteForm = () => {
    setAddProjectModal(false);
    setEditProjectModal(false);
    setDeleteProjectModal(false);
    setProject(null);
  };

  const onProjectAddClick = async (evt: any, project: any) => {
    evt.preventDefault();

    const {
      sLibelle,
      sDescription,
      sIntroduction,
      sAvancement,
      sAideUrl,
      sDocUrl,
      iDuree,
      fBudget,
      dLivraisonSouhaitee,
      tDocument,
      tStructure,
    } = project;

    let errors: any = {};

    if (!sLibelle) {
      errors.sLibelle = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const response = await axios.post<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projet`,
      {
        sLibelle,
        sDescription,
        sIntroduction,
        sAvancement,
        sAideUrl,
        sDocUrl,
        iDuree: Number(iDuree),
        fBudget: Number(fBudget),
        dLivraisonSouhaitee,
        tDocument,
        tStructure,
      }
    );
    if (response.data.projet) {
      projects.push(response.data.projet);
      setProjects(projects);
      onCloseProjectAddForm();
    }
  };

  const onProjectEditClick = async (evt: any, project: any) => {
    evt.preventDefault();

    const {
      iPKProjet,
      sLibelle,
      sDescription,
      sIntroduction,
      sAvancement,
      sAideUrl,
      sDocUrl,
      iDuree,
      fBudget,
      dLivraisonSouhaitee,
      tDocument,
      tStructure,
    } = project;
    const response = await axios.put<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projet`,
      {
        iPKProjet,
        sLibelle,
        sDescription,
        sIntroduction,
        sAvancement,
        sAideUrl,
        sDocUrl,
        iDuree: Number(iDuree),
        fBudget: Number(fBudget),
        dLivraisonSouhaitee,
        tDocument,
        tStructure,
      }
    );
    if (response.data.projet) {
      setProjects(
        projects.map((p) =>
          p.iPKProjet === response.data.projet.iPKProjet
            ? response.data.projet
            : p
        )
      );
      onCloseProjectEditForm();
    }
  };

  const onProjectDeleteClick = async (evt: any, project: any) => {
    evt.preventDefault();

    const { iPKProjet } = project;
    const response = await axios.delete<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projet`,
      {
        data: { iPKProjet },
      }
    );
    if (response.data.projet) {
      setProjects(
        projects.filter((p) => p.iPKProjet !== response.data.projet.iPKProjet)
      );
      onCloseProjectEditForm();
    }
  };

  if (!projectsLoaded) {
    return <Loader style={{marginTop: 25}} active />;
  }

  return (
    <>
      {projects && utilisateur.identite.iRole > 0 && selection.length > 0 ? (
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th></Table.Th>
              {type === 2 && <Table.Th>Durée du<br />projet</Table.Th>}
              {(isDirection || isAdmin) && <Table.Th>Budget Global</Table.Th>}
              {type === 2 && isDirection && (
                <Table.Th>
                  Votre <br />
                  Participation
                </Table.Th>
              )}
              {type === 1 && <Table.Th>Avancement</Table.Th>}
              {type === 2 && (isAdmin || isDirection) && (
                <>
                  <Table.Th style={{ textAlign: "center" }}>Décision</Table.Th>
                  <Table.Th style={{ textAlign: "center" }}>Structures<br />hors projet</Table.Th>
                  <Table.Th />
                </>
              )}

              {isAdmin && <Table.ThActions>Actions</Table.ThActions>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {selection.map((p) => {
              let cumule = 0;
              let budgetStructure = 0;
              let percentApprouve = 0;
              let percentRefuse = 0;
              let nbApprouve = 0;
              let nbRefuse = 0;
              const htmlClientPour:Array<any> = [];
              const htmlClientContre:Array<any> = [];
              const htmlClientEnAttente:Array<any> = [];
              isAdmin && htmlClientPour.push(<div>Structure{nbApprouve > 1 ? 's' : ''} ayant voté : </div>);
              isAdmin && htmlClientContre.push(<div>Structure{nbRefuse > 1 ? 's' : ''} ayant voté : </div>);
              isAdmin && htmlClientEnAttente.push(<div>Structure{p.tStructure.length - nbApprouve -  nbRefuse > 1 ? 's' : ''} en attente{p.tStructure.length - nbApprouve -  nbRefuse > 1 ? 's' : ''} : </div>);
              if (p.tStructure) {
                p.tStructure.forEach((s: any) => {
                  if(p.tVote.find((v: any) => s.iPKStructure === v.iPKStructure && v.bVote === 1)) {
                    nbApprouve++;
                    isAdmin && htmlClientPour.push(<div>{s.sLibelle}</div>);
                    percentApprouve += s.fPartCout;
                  } else if(p.tVote.find((v: any) => s.iPKStructure === v.iPKStructure && v.bVote === 0)) {
                    nbRefuse++;
                    isAdmin && htmlClientContre.push(<div>{s.sLibelle}</div>);
                    percentRefuse += s.fPartCout;
                  } else {
                    isAdmin && htmlClientEnAttente.push(<div>{s.sLibelle}</div>);
                  }
                  cumule += s.fPartCout;
                });

                const budgetRestant = (p.fBudget * (100 - cumule)) / 100;
                percentApprouve = percentApprouve * (100 / cumule);
                percentRefuse = percentRefuse * (100 / cumule);
                if (budgetRestant > 0) {
                  const ratioGlobal = 100 / cumule;
                  const ratioStructure =
                    100 / structure.fPartCout / ratioGlobal;
                  budgetStructure = budgetRestant / ratioStructure;
                }
              }

              return (
                <>
                <Table.Tr key={p.iPKProjet}>
                  <Table.Td style={{ maxWidth: 600 }}>
                    <Table.TdLink to={`/projets/${p.iPKProjet}/${p.sLibelle}`}>
                      <b>{p.sLibelle}</b>
                      <div>{p.sDescription}</div>
                    </Table.TdLink>
                  </Table.Td>
                  {type === 2 && <Table.Td>
                    <Table.TdLink to={`/projets/${p.iPKProjet}/${p.sLibelle}`}>
                      {p.iDuree === 0
                        ? "En cours d'évaluation"
                        : `${p.iDuree} mois`}
                    </Table.TdLink>
                  </Table.Td>}
                  {(isDirection || isAdmin) && (
                    <Table.Td>
                      <Table.TdLink
                        style={{ maxWidth: 90 }}
                        to={`/projets/${p.iPKProjet}/${p.sLibelle}`}
                      >
                        {p.fBudget === 0
                          ? "En cours d'évaluation"
                          : `${p.fBudget} €`}
                      </Table.TdLink>
                    </Table.Td>
                  )}
                  {type === 2 && isDirection && (
                    <Table.Td>
                      <Table.TdLink
                        to={`/projets/${p.iPKProjet}/${p.sLibelle}`}
                      >
                        {!p.tStructure.find(
                          (s: any) => s.iPKStructure === structure.iPKStructure
                        )
                          ? "Vous ne souhaitez pas participer à ce projet"
                          : p.fBudget === 0
                          ? "En cours d'évaluation"
                          : ""}
                        {p.fBudget !== 0 &&
                          structure &&
                          p.tStructure.find(
                            (s: any) =>
                              s.iPKStructure === structure.iPKStructure
                          ) &&
                          `${Math.floor(
                            (p.fBudget * structure.fPartCout) / 100 +
                              budgetStructure
                          )} €`}
                        {p.fBudget !== 0 &&
                          p.iDuree > 0 &&
                          structure &&
                          p.tStructure.find(
                            (s: any) =>
                              s.iPKStructure === structure.iPKStructure
                          ) && (
                            <>
                              {" "}
                              soit{" "}
                              {Math.floor(
                                ((p.fBudget * structure.fPartCout) / 100 +
                                  budgetStructure) /
                                  p.iDuree
                              )}
                              &nbsp;€ par mois durant {p.iDuree} mois
                            </>
                          )}
                      </Table.TdLink>
                    </Table.Td>
                  )}
                  {type === 1 && (
                    <Table.Td>
                      <Table.TdLink
                        to={`/projets/${p.iPKProjet}/${p.sLibelle}`}
                      >
                        {p.sAvancement}
                      </Table.TdLink>
                    </Table.Td>
                  )}
                  {type === 2 && (isAdmin || isDirection) && (
                    <>
                      <Table.Td style={{ textAlign: "center" }}>
                          <Progress
                            percentApprouve={percentApprouve}
                            nbApprouve={nbApprouve}
                            nbRefuse={nbRefuse}
                            percentRefuse={percentRefuse}
                            iTotal={p.tStructure.length}
                            htmlClientPour={htmlClientPour}
                            htmlClientContre={htmlClientContre}
                            htmlClientEnAttente={htmlClientEnAttente}
                          />
                      </Table.Td>
                      <Table.Td style={{ textAlign: "center" }}>
                          {(p.tStructure) && p.tStructure.length < structures.length &&
                           <Popup
                              trigger={<Icon name='eye' />}
                              content={ (p.tStructure) && structures.map((s:any) => {
                                if(!p.tStructure.find((ps: any) => s.iPKStructure === ps.iPKStructure)) {
                                  return <div>{s.sLibelle}</div>;
                                }
                              })}
                          />}
                      </Table.Td>
                      <Table.TdActions style={{ paddingTop: 6 }}>
                        {!p.tVote.filter(
                          (v: any) =>
                            v.iFKUtilisateur ===
                            utilisateur.identite.iPKUtilisateur
                        )[0] &&
                          p.tStructure.find(
                            (s: any) =>
                              s.iPKStructure === structure.iPKStructure
                          ) &&
                          p.fBudget > 0 && (
                            <ButtonEdit
                              onClick={() => {
                                window.location.href = `/projets/${p.iPKProjet}/${p.sLibelle}`;
                              }}
                            >
                              Voter
                            </ButtonEdit>
                          )}
                      </Table.TdActions>
                    </>
                  )}
                  {isAdmin && (
                    <Table.TdActions>
                      <ButtonEdit onClick={(evt) => onBtnEditClick(evt, p)}>
                        Editer
                      </ButtonEdit>
                      <ButtonDelete onClick={(evt) => onBtnDeleteClick(evt, p)}>
                        Supprimer
                      </ButtonDelete>
                    </Table.TdActions>
                  )}
                </Table.Tr>
                </>
              );
            })}
            {isAdmin && (
              <Table.TrActions>
                <Table.Td colSpan={8}>
                  <ButtonAdd onClick={onBtnAddClick}>
                    Ajouter un projet
                  </ButtonAdd>
                </Table.Td>
              </Table.TrActions>
            )}
          </Table.Tbody>
        </Table.Table>
      ) : (
        <>
          <p>Aucun projet...</p>
          {isAdmin && (
            <ButtonAdd onClick={onBtnAddClick}>Ajouter un projet</ButtonAdd>
          )}
        </>
      )}

      {addProjectModal && (
        <ProjectForm
          title="Ajouter un projet"
          errors={errors}
          open={addProjectModal}
          onSave={onProjectAddClick}
          onClose={onCloseProjectAddForm}
        />
      )}

      {editProjectModal && (
        <ProjectForm
          title="Editer un projet"
          errors={errors}
          project={project}
          open={editProjectModal}
          onSave={onProjectEditClick}
          onClose={onCloseProjectEditForm}
        />
      )}

      {deleteProjectModal && (
        <Confirm
          message="Souhaitez-vous supprimer ce projet ainsi que toutes ses conversations et événements de l'agenda ?"
          onConfirm={(evt) => onProjectDeleteClick(evt, project)}
          onCancel={onCloseProjectDeleteForm}
        />
      )}
    </>
  );
};

export default ProjectsList;
