import React, { FunctionComponent } from "react";
import { Grid } from "semantic-ui-react";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { Hero } from "./elements/hero/hero.element";

const AccueilView: FunctionComponent = () => {
  return (
    <AnimatedView title="Accueil">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Hero />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default AccueilView;
