import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { DateTime } from "luxon";

import { selectUtilisateur } from "../../../../../redux/utilisateur/utilisateur.slice";

import { Evenement } from "../../../../../interfaces/projet.interface";

import { Icon, Modal } from "semantic-ui-react";

import * as Table from "../../../../core/layout/view/table.elements";
import Confirm from "../../../../core/layout/view/confirm.elements";
import {
  ButtonAdd,
  ButtonDelete,
  ButtonEdit,
} from "../../../../core/layout/button/button.elements";

import "moment/locale/fr";

import EventForm from "../Form";

const Wrapper = styled.div`
  margin: 0 0 20px;
`;

interface Reponse {
  evenement: Evenement;
}

interface Props {
  events: any[];
  projects?: any[];
}

const EventsList: FunctionComponent<Props> = ({ events, projects }: Props) => {
  const [addEventModal, setAddEventModal] = useState<boolean>(false);
  const [editEventModal, setEditEventModal] = useState<boolean>(false);
  const [deleteEventModal, setDeleteEventModal] = useState<boolean>(false);
  const [event, setEvent] = useState<any>(null);
  const [tEvent, setEvents] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const utilisateur = useSelector(selectUtilisateur);
  const isAdmin = utilisateur.identite.iRole === 4;
  /*const isDirection = utilisateur.identite.iRole === 3;
  const isDSI = utilisateur.identite.iRole === 2;*/
  const [displayInfo, setDisplayInfo] = useState<any>({});
  const [displayCR, setDisplayCR] = useState<any>({});

  useEffect(() => {
    if (projects && projects.length === 1) {
      setEvent({ iFKProjet: projects[0].iPKProjet });
    }
    if (!tEvent || (tEvent && tEvent.length === 0)) {
      setEvents(events);
    }
  }, [events, projects, tEvent]);

  const onBtnAddClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddEventModal(true);
    setEditEventModal(false);
    setDeleteEventModal(false);
  };

  const onBtnEditClick = (evt: any, event: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddEventModal(false);
    setEditEventModal(true);
    setDeleteEventModal(false);
    setEvent(event);
  };

  const onBtnDeleteClick = (evt: any, event: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddEventModal(false);
    setEditEventModal(false);
    setDeleteEventModal(true);
    setEvent(event);
  };

  const onCloseEventAddForm = () => {
    setAddEventModal(false);
    setEditEventModal(false);
    setDeleteEventModal(false);
    setEvent(null);
  };

  const onCloseEventEditForm = () => {
    setAddEventModal(false);
    setEditEventModal(false);
    setDeleteEventModal(false);
    setEvent(null);
  };

  const onCloseEventDeleteForm = () => {
    setAddEventModal(false);
    setEditEventModal(false);
    setDeleteEventModal(false);
    setEvent(null);
  };

  const onEventAddClick = async (evt: any, event: any) => {
    evt.preventDefault();

    const {
      sLibelle,
      sInformation,
      sCompteRendu,
      sLieu,
      date,
      start,
      end,
      iFKProjet,
    } = event;

    let errors: any = {};

    if (!date) {
      errors.date = true;
    }
    if (!start) {
      errors.start = true;
    }
    if (!end) {
      errors.end = true;
    }
    if (!sLibelle) {
      errors.sLibelle = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const dDebut = DateTime.fromJSDate(new Date(date))
      .set({
        hour: start.split(":")[0],
        minute: start.split(":")[1],
        second: 0,
      })
      .toISO();
    const dFin = DateTime.fromJSDate(new Date(date))
      .set({
        hour: end.split(":")[0],
        minute: end.split(":")[1],
      })
      .toISO();

    const response = await axios.post<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/evenement`,
      {
        sLibelle,
        sInformation,
        sCompteRendu,
        sLieu,
        dDebut,
        dFin,
        iFKProjet,
      }
    );
    if (response.data.evenement) {
      tEvent.push({
        ...response.data.evenement,
        ...{
          Projet: {
            sLibelle: projects && projects[0].sLibelle,
            iPKProjet: iFKProjet,
          },
        },
      });
      setEvents(tEvent);
      onCloseEventAddForm();
    }
  };

  const onEventEditClick = async (evt: any, event: any) => {
    evt.preventDefault();

    const {
      iPKEvenement,
      sLibelle,
      sInformation,
      sCompteRendu,
      sLieu,
      date,
      start,
      end,
      iFKProjet,
    } = event;

    if (!date) {
      errors.date = true;
    }
    if (!start) {
      errors.start = true;
    }
    if (!end) {
      errors.end = true;
    }
    if (!sLibelle) {
      errors.sLibelle = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const dDebut = DateTime.fromJSDate(new Date(date))
      .set({
        hour: start.split(":")[0],
        minute: start.split(":")[1],
        second: 0,
      })
      .toISO();
    const dFin = DateTime.fromJSDate(new Date(date))
      .set({
        hour: end.split(":")[0],
        minute: end.split(":")[1],
      })
      .toISO();

    const response = await axios.put<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/evenement`,
      {
        iPKEvenement,
        sLibelle,
        sInformation,
        sCompteRendu,
        sLieu,
        dDebut,
        dFin,
        iFKProjet,
      }
    );
    if (response.data.evenement) {
      console.log({
        ...response.data.evenement,
        ...{
          Projet: {
            sLibelle: projects && projects[0].sLibelle,
            iPKProjet: iFKProjet,
          },
        },
      });
      const updatedTevent = tEvent.map((e) => {
        console.log(e.iPKEvenement);
        console.log(response.data.evenement.iPKEvenement);
        return e.iPKEvenement === response.data.evenement.iPKEvenement
          ? {
              ...response.data.evenement,
              ...{
                Projet: {
                  sLibelle: projects && projects[0].sLibelle,
                  iPKProjet: iFKProjet,
                },
              },
            }
          : e;
      });
      setEvents(updatedTevent);
      onCloseEventEditForm();
    }
  };

  const onEventDeleteClick = async (evt: any, event: any) => {
    evt.preventDefault();

    const { iPKEvenement } = event;
    const response = await axios.delete<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/evenement`,
      {
        data: { iPKEvenement },
      }
    );
    if (response.data.evenement) {
      setEvents(
        tEvent.filter(
          (e) => e.iPKEvenement !== response.data.evenement.iPKEvenement
        )
      );
      onCloseEventEditForm();
    }
  };

  const toggleDisplayInfo = (evt: any, iPKEvenement: any) => {
    setDisplayInfo({
      [iPKEvenement]: !displayInfo[iPKEvenement],
    });
  };

  return (
    <>
      {tEvent && tEvent.length > 0 ? (
        <Wrapper>
          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Début</Table.Th>
                <Table.Th>Fin</Table.Th>
                <Table.Th>Projet</Table.Th>
                <Table.Th>Libelle</Table.Th>
                <Table.Th>Lieu</Table.Th>
                <Table.Th>CR</Table.Th>
                {isAdmin && <Table.ThActions>Actions</Table.ThActions>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tEvent.map((event: any) => (
                <>
                  <Table.Tr key={event.iPKEvenement}>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {DateTime.fromISO(event.dDebut).toFormat("dd/LL/yyyy")}{" "}
                        {(DateTime.fromISO(event.dDebut)
                          .diff(DateTime.local())
                          .toObject().milliseconds || 0) < 0 && (
                          <Icon name="check" />
                        )}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {console.log(
                          DateTime.fromISO(event.dDebut).toISOTime()
                        )}
                        {DateTime.fromISO(event.dDebut).toFormat("HH:mm")}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {DateTime.fromISO(event.dFin).toFormat("HH:mm")}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {event.Projet.sLibelle}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {event.sLibelle}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      <Table.TdLink
                        to="#"
                        onClick={(evt) =>
                          toggleDisplayInfo(evt, event.iPKEvenement)
                        }
                      >
                        {event.sLieu}
                      </Table.TdLink>
                    </Table.Td>
                    <Table.Td>
                      {event.sCompteRendu && (
                        <span
                          onClick={(evt) =>
                            setDisplayCR({ [event.iPKEvenement]: true })
                          }
                          style={{ cursor: "pointer", fontSize: 20 }}
                          data-tooltip="Compte rendu"
                        >
                          <Icon name="file alternate outline" />
                        </span>
                      )}
                    </Table.Td>
                    {isAdmin && (
                      <Table.TdActions>
                        <ButtonEdit
                          onClick={(evt) => onBtnEditClick(evt, event)}
                        >
                          Editer
                        </ButtonEdit>
                        <ButtonDelete
                          onClick={(evt) => onBtnDeleteClick(evt, event)}
                        >
                          Supprimer
                        </ButtonDelete>
                      </Table.TdActions>
                    )}
                  </Table.Tr>
                  {!!displayInfo[event.iPKEvenement] && event.sInformation && (
                    <Table.Tr>
                      <Table.Td
                        style={{ padding: 10, whiteSpace: "pre-wrap" }}
                        colSpan={6}
                      >
                        {event.sInformation}
                      </Table.Td>
                    </Table.Tr>
                  )}
                  <Modal
                    size="large"
                    centered={false}
                    dimmer="inverted"
                    open={!!displayCR[event.iPKEvenement]}
                    onClose={() =>
                      setDisplayCR({ [event.iPKEvenement]: false })
                    }
                    closeIcon
                  >
                    <Modal.Content style={{ whiteSpace: "pre-wrap" }}>
                      {event.sCompteRendu}
                    </Modal.Content>
                  </Modal>
                </>
              ))}
            </Table.Tbody>
          </Table.Table>
        </Wrapper>
      ) : (
        <p>Aucun événement...</p>
      )}
      {isAdmin && (
        <ButtonAdd onClick={onBtnAddClick}>Ajouter un rendez-vous</ButtonAdd>
      )}

      {addEventModal && (
        <EventForm
          title="Ajouter un événement"
          open={addEventModal}
          errors={errors}
          projects={projects}
          onSave={onEventAddClick}
          onClose={onCloseEventAddForm}
        />
      )}

      {editEventModal && (
        <EventForm
          title="Editer un événement"
          event={event}
          errors={errors}
          projects={projects}
          open={editEventModal}
          onSave={onEventEditClick}
          onClose={onCloseEventEditForm}
        />
      )}

      {deleteEventModal && (
        <Confirm
          message="Souhaitez-vous supprimer cet événement de l'agenda ?"
          onConfirm={(evt) => onEventDeleteClick(evt, event)}
          onCancel={onCloseEventDeleteForm}
        />
      )}
    </>
  );
};

export default EventsList;
