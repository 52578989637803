import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

import { connexionSucces } from "./redux/utilisateur/utilisateur.slice";
import * as jwt from "jsonwebtoken";
import { Identite } from "./interfaces/utilisateur.interface";

axios.defaults.withCredentials = true;

function getCookie(name: string) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

const authorization = getCookie("Authorization");

if (authorization) {
  jwt.verify(
    authorization,
    `${process.env.REACT_APP_JWT_PUBLIC}`,
    (err, decoded) => {
      if (err) {
        console.log(err);
      } else {
        store.dispatch(connexionSucces(decoded as Identite));
      }
    }
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
