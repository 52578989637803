import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const Logo = styled.img`
  margin-top: 10px;
  margin-bottom: -15px;
  width: auto;
  height: 40px;
`;

export const Menu = styled.ul`
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li`
  display: inline-block;
  list-style: none;
  border-right: 1px solid ${(props) => props.theme.border.light};
`;

export const UserItem = styled(MenuItem)`
  float: right;
  border-right: none;
  border-right: 1px solid ${(props) => props.theme.border.light};
  font-family: "Roboto Light";
  font-weight: 700;
  padding: 0 10px;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.primary.light};
  }

  @media (max-width: 425px) {
    font-size: 15px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
    padding: 0 5px;
  }
`;

export const Link = styled(NavLink)`
  display: inline-block;
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  font-family: "Roboto Bold";
  font-size: 18px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.primary.dark};
  background: ${(props) => props.theme.button.primary.text};

  &:hover {
    color: ${(props) => props.theme.colors.primary.light};
    background: ${(props) => props.theme.button.primary.text};
  }

  &.active {
    color: ${(props) => props.theme.colors.primary.light};
    background: ${(props) => props.theme.button.primary.text};
  }

  @media (min-width: 320px) {
    padding: 0 10px;
  }
`;

export const BtnLogIn = styled(NavLink)`
  display: inline-block;
  line-height: 60px;
  font-family: "Roboto Light";
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.dark};
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  padding-left: 10px;

  &:hover {
    color: ${(props) => props.theme.colors.primary.light};
  }
`;

export const Prenom = styled.span``;
export const Nom = styled.span`
  text-transform: uppercase;
`;

export const IconMobile = styled(Icon)`
  @media (min-width: 425px) {
    display: none;
  }
`;

export const HideOnMobile = styled.span`
  @media (max-width: 425px) {
    display: none;
  }
`;
