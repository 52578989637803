import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import {
  ReponseCharteResultat,
  ReponsesCharte,
} from "../../interfaces/reponseCharte.interface";

const initialState: ReponsesCharte = {
  tReponse: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "reponsesCharte",
  initialState,
  reducers: {
    reponseCharteSucces: (
      state,
      { payload }: PayloadAction<ReponseCharteResultat[]>
    ) => {
      state.tReponse = payload;
      state.sErreur = "";
    },
    reponseCharteErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { reponseCharteSucces, reponseCharteErreur } = slice.actions;
export const selectStoreErreur = (state: Store) => state.reponsesCharte.sErreur;

export default slice.reducer;
