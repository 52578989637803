import styled from "styled-components";

export const Form = styled.form`
  display: block;
`;

export const Field = styled.div<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? "flex" : "block")};
  align-items: ${(props) => (props.inline ? "center" : "")};
  justify-content: ${(props) => (props.inline ? "center" : "")};
  margin: 0 0 15px;
`;

export const CommandField = styled.div`
  display: flex;
  margin: 20px 0 0;
  align-items: center;
  justify-content: center;
`;

export const Legend = styled.div`
  margin: 20px 0 10px;
  padding: 0 0 5px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
`;

export const Label = styled.label<{ inline?: boolean }>`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  margin: 0 0 5px;
  padding-right: ${(props) => (props.inline ? "5px" : "0")};
  width: ${(props) => (props.inline ? "33%" : "100%")};
  text-align: ${(props) => (props.inline ? "right" : "left")};
  font-weight: bold;
`;

export const Input = styled.input`
  padding: 10px !important;
  width: 100%;
  border: 1px solid lightgrey !important;
  border-radius: 5px;
  &:focus {
    border-color: black !important;
    border-width: 2px !important;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
  }
`;

export const Textarea = styled.textarea`
  padding: 10px;
  width: 100%;
  max-width: 100%;
  height: 100px;
  border: 1px solid lightgrey;
  border-radius: 5px;
`;

export const Select = styled.select`
  padding: 5px;
  width: 66%;
`;

export const Checkbox = styled.input`
  display: inline-block;
  margin: 0 0 0 33%;
  width: 20px;
  height: 20px;
`;
