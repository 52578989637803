import * as React from "react";
import { Switch, useLocation, useHistory } from "react-router-dom";

export interface Props {
  children: JSX.Element[] | JSX.Element;
}

export default (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname === "/connexion" && history.action !== "PUSH") {
    return (
      <Switch location={location} key={location.pathname}>
        {props.children}
      </Switch>
    );
  }

  return (
    <Switch location={location} key={location.pathname}>
      {props.children}
    </Switch>
  );
};
