import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { Title } from "../../core/layout/view/title.elements";
import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { Card, CardTitle } from "../../core/layout/view/card.elements";

import ProjectList from "./elements/List";

const ProjetsView = ({
  isAdmin,
  isDirection,
  isDSI,
  isUtilisateur,
  isGuest,
}) => (
  <AnimatedView title="Projets">
    <Main>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Title>Projets</Title>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        {isGuest && (
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="flex-col"
            >
              <Link to="/connexion">
                Connectez-vous pour accéder aux projets.
              </Link>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column width={8}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </Grid.Column>
        </Grid.Row>

        {(isUtilisateur || isDSI || isDirection || isAdmin) && (
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="flex-col"
            >
              <Card>
                <CardTitle>Projets à valider</CardTitle>

                <ProjectList type={2} />
              </Card>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Main>
  </AnimatedView>
);

ProjetsView.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isDirection: PropTypes.bool.isRequired,
  isDSI: PropTypes.bool.isRequired,
  isUtilisateur: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: state.utilisateur.identite.iRole === 4,
  isDirection: state.utilisateur.identite.iRole === 3,
  isDSI: state.utilisateur.identite.iRole === 2,
  isUtilisateur: state.utilisateur.identite.iRole === 1,
  isGuest: state.utilisateur.identite.iRole === 0,
});

export default connect(mapStateToProps)(ProjetsView);
