import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";

import axios from "axios";
import { DateTime } from "luxon";

import { DatePickerInput } from "rc-datepicker";
import {
  Modal,
  Form,
  Input,
  TextArea,
  Button,
  Icon,
  Loader,
  Select,
} from "semantic-ui-react";

import styled from "styled-components";

import "../../../../../ui/assets/css/datepicker.css";
import "moment/locale/fr";

import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";
import { Projet } from "../../../../../interfaces/projet.interface";

const BtnSave = styled(ButtonAdd)`
  margin: 0 0 0 15px;
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
`;

interface Props {
  title: String;
  errors?: any;
  project?: Projet;
  open: boolean | undefined;
  onSave: (event: React.MouseEvent<HTMLButtonElement>, projet: any) => void;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ProjectAddForm: FunctionComponent<Props> = ({
  title,
  errors,
  project,
  open,
  onSave,
  onClose,
}: Props) => {
  const [projet, setProjet] = useState<any>({
    iPKProjet: 0,
    sLibelle: "",
    sIntroduction: "",
    sDescription: "",
    sAvancement: "",
    fBudget: "",
    iDuree: "",
    sDocUrl: "",
    sAideUrl: "",
    dLivraisonSouhaitee: null,
    tDocument: [],
    tStructure: [],
  });

  const [bUploadFile, setbUploadFile] = useState<Boolean>(false);
  const [structures, setStructures] = useState<any>([]);
  const listStructure = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/structures`
    );
    setStructures(response.data.tStructure);
  }, []);

  useEffect(() => {
    if (project) {
      setProjet(project);
    }
    listStructure();
  }, [project, listStructure]);

  const fileInputRef = React.createRef<HTMLInputElement>();

  const onFieldChange = (evt: any, data: any) => {
    setProjet({ ...projet, [data.id]: data.value });
  };

  const onSelectChange = (evt: any, data: any) => {
    console.log(data);
    setProjet({
      ...projet,
      [data.id]: data.value.map((i: number) => ({ iPKStructure: i })),
    });
  };

  const onDocumentLibelleChange = (evt: any, data: any) => {
    const { tDocument } = projet;

    setProjet({
      ...projet,
      tDocument: tDocument.map((d: any, i: number) =>
        i === data.documentindex ? { ...d, [data.id]: data.value } : d
      ),
    });
  };

  const onFileChange = async (evt: any) => {
    const { tDocument } = projet;
    const {
      target: { files },
    } = evt;
    const formData = new FormData();
    formData.append("file", files[0], files[0].name);

    setbUploadFile(true);
    const document = await axios.post(
      `${process.env.REACT_APP_GRAPHQL_URL}/document`,
      formData
    );

    const idx = files[0].name.lastIndexOf(".");
    const sLibelle = `${files[0].name.substring(0, idx).trim()}`;
    tDocument.push({
      sLibelle: sLibelle,
      name: files[0].name,
      size: files[0].size,
      sTmpFilename: document.data.sTmpFilename,
      sUrl: document.data.sUrl,
      content: files[0],
      type: files[0].type,
    });
    setProjet({
      ...projet,
      tDocument,
    });
    setbUploadFile(false);
  };

  const deleteFile = (evt: any, documentid: any) => {
    const { tDocument } = projet;
    setProjet({
      ...projet,
      tDocument: tDocument.map((document: any, i: number) =>
        i === documentid ? { ...document, bDelete: true } : document
      ),
    });
  };

  const onDateChange = (date: any) => {
    setProjet({
      ...projet,
      dLivraisonSouhaitee:
        date !== null ? DateTime.fromJSDate(date).toISODate() : null,
    });
  };

  return (
    <Modal
      size="small"
      centered={false}
      dimmer="inverted"
      open={open}
      onClose={onClose}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Field
              control={Input}
              width={16}
              id="sLibelle"
              label="Libelle"
              value={projet.sLibelle}
              error={!!errors.sLibelle}
              onChange={onFieldChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={TextArea}
              width={16}
              id="sIntroduction"
              label="Introduction"
              value={projet.sIntroduction}
              error={!!errors.sIntroduction}
              onChange={onFieldChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={TextArea}
              width={16}
              id="sDescription"
              label="Description"
              value={projet.sDescription}
              error={!!errors.sDescription}
              onChange={onFieldChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Input}
              type="number"
              icon="euro"
              width={16}
              id="fBudget"
              label="Budget"
              value={projet.fBudget}
              error={!!errors.fBudget}
              onChange={onFieldChange}
            />
            <Form.Field
              control={Input}
              type="number"
              width={16}
              id="iDuree"
              label="Durée estimée en mois"
              value={projet.iDuree}
              error={!!errors.iDuree}
              onChange={onFieldChange}
            />
            <Form.Field
              control={Input}
              width={16}
              id="sAvancement"
              label="Avancement du projet"
              value={projet.sAvancement}
              error={!!errors.sAvancement}
              onChange={onFieldChange}
            />
            <Form.Field width={16}>
              <label htmlFor="dLivraisonSouhaitee">
                Livraison souhaitée le
              </label>
              <DatePickerInput
                onChange={onDateChange}
                value={projet.dLivraisonSouhaitee}
                locale="fr"
                format="DD/MM/YYYY"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Input}
              width={8}
              id="sDocUrl"
              label="Lien de la documentation"
              value={projet.sDocUrl}
              error={!!errors.sDocUrl}
              onChange={onFieldChange}
            />
            <Form.Field
              control={Input}
              width={8}
              id="sAideUrl"
              label="Lien de l'aide en ligne"
              value={projet.sAideUrl}
              error={!!errors.sAideUrl}
              onChange={onFieldChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              control={Select}
              multiple
              width={16}
              id="tStructure"
              label="Structures prisent en compte pour les votes"
              options={structures.map((s: any) => ({
                key: s.iPKStructure,
                value: s.iPKStructure,
                text: s.sLibelle,
              }))}
              value={projet.tStructure.map((s: any) => s.iPKStructure)}
              error={!!errors.sDocUrl}
              onChange={onSelectChange}
            />
          </Form.Group>
          <Form.Group>
            <Button
              style={{ marginLeft: 7 }}
              content="Choisir un fichier"
              labelPosition="left"
              icon="file"
              onClick={() => fileInputRef.current!.click()}
            />
            <input
              ref={fileInputRef}
              type="file"
              hidden
              onChange={onFileChange}
            />
            {bUploadFile && <Loader active inline />}
          </Form.Group>
          {projet.tDocument.length > 0 &&
            projet.tDocument
              .filter((d: any) => !d.bDelete)
              .map(
                (document: any, i: number) =>
                  !document.sImgPath && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Form.Field
                        control={Input}
                        width={8}
                        documentindex={i}
                        id="sLibelle"
                        label="Libelle du document"
                        value={document.sLibelle}
                        error={!!errors.sAvancement}
                        onChange={onDocumentLibelleChange}
                      />
                      <div
                        style={{
                          paddingTop: "20px",
                          height: 40,
                          lineHeight: "40px",
                          marginLeft: 10,
                          verticalAlign: "middle",
                        }}
                      >
                        <a
                          href={`${process.env.REACT_APP_FILE_URL}${
                            document.iPKDocument ? "/" : "/tmp/"
                          }${document.sUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon size="large" name="eye" />
                        </a>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => deleteFile(e, i)}
                        >
                          <Icon size="large" color="red" name="delete" />
                        </span>
                      </div>
                    </div>
                  )
              )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <BtnCancel onClick={onClose}>Annuler</BtnCancel>
        <BtnSave onClick={(evt) => onSave(evt, projet)}>Enregistrer</BtnSave>
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectAddForm;
