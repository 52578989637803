import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { dispatchReponseCharte } from "../../../../redux/reponseCharte/actions/reponseCharte.action";
import { selectStoreErreur } from "../../../../redux/questionCharte/questionCharte.slice";
import { selectUtilisateur } from "../../../../redux/utilisateur/utilisateur.slice";

import {
  Form,
  Field,
  CommandField,
  Textarea,
  Label,
} from "../../../core/layout/form/form.elements";
import { Button } from "../../../core/layout/button/button.elements";
import {
  ErreurNotice,
  SuccessNotice,
} from "../../../core/layout/notice/notice.elements";

export const ConteneurQuestion = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  background-color: ${(props) => props.theme.background.light};
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const BtnAnnule = styled(Button)`
  margin-left: 10px;
  background-color: ${(props) => props.theme.colors.neutral.main};
`;

export const StyleLink = styled(Link)`
  .grid & {
    color: ${(props) => props.theme.colors.primary.dark} !important;
    text-decoration: underline;
  }
`;

interface Props {
  closeForm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setNouvelleReponse: (event: React.SetStateAction<boolean>) => void;
  setAfficheForm: (event: React.SetStateAction<boolean>) => void;
  bAfficheForm: boolean;
  iPKQuestionCharte: number;
}

const ReponseElement = (props: Props) => {
  const dispatch = useDispatch();
  const [sReponse, setReponse] = useState<string>("");
  const [sErreur, setErreur] = useState<string>("");
  const [bSuccess, setSuccess] = useState<boolean>(false);
  const utilisateur = useSelector(selectUtilisateur);
  const sStoreErreur = useSelector(selectStoreErreur);

  useEffect(() => {
    setSuccess(false);
  }, []);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();

    if (isValidForm()) {
      setReponse("");
      dispatch(
        dispatchReponseCharte(
          utilisateur.identite.iPKUtilisateur,
          props.iPKQuestionCharte,
          sReponse
        )
      );
      setSuccess(true);
    }
  };

  const isValidForm = (): boolean => {
    if (sReponse.length === 0) {
      setErreur("Veuillez saisir votre réponse");
      return false;
    }

    return true;
  };

  if (bSuccess) {
    props.setNouvelleReponse(true);
    props.setAfficheForm(false);
    setTimeout(() => {
      setSuccess(false);
      props.setNouvelleReponse(false);
    }, 3000);
  }

  return (
    <ConteneurQuestion>
      {bSuccess && (
        <SuccessNotice>Votre réponse a bien été prise en compte.</SuccessNotice>
      )}
      {props.bAfficheForm && (
        <Form style={{ padding: 15 }} onSubmit={handleSubmit}>
          <Field>
            <Label>Saisissez votre réponse :</Label>
            <Textarea
              value={sReponse}
              onChange={(evt) => setReponse(evt.target.value)}
            />
          </Field>
          <CommandField>
            <Button type="submit">Envoyer</Button>
            <BtnAnnule onClick={props.closeForm}>Annuler</BtnAnnule>
          </CommandField>
          {sErreur && <ErreurNotice>{sErreur}</ErreurNotice>}
          {sStoreErreur && <ErreurNotice>{sStoreErreur}</ErreurNotice>}
        </Form>
      )}
    </ConteneurQuestion>
  );
};

export default ReponseElement;
