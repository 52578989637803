import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Utilisateur } from "../../../interfaces/utilisateur.interface";
import { deconnexion } from "../utilisateur.slice";

export const DispatchDeconnexion = (): ThunkAction<
  void,
  Utilisateur,
  unknown,
  Action<string>
> => async (dispatch) => {
  document.cookie = "Authorization=";
  dispatch(deconnexion());
};
