import styled from "styled-components";

export const InfoNotice = styled.div`
  display: inline-block;
  padding: 5px 10px;
`;

export const ErreurNotice = styled(InfoNotice)`
  margin-top: 10px;
  width: 100%;
  background: rgba(255, 0, 0, 0.25);
`;

export const SuccessNotice = styled(InfoNotice)`
  padding: 10px;
  width: 100%;
  background: #93c65359;
`;
