import * as React from 'react';
import { Redirect, Route, useLocation, RouteProps } from 'react-router-dom';
import { useSelector } from "react-redux";

import { selectConnecte } from "../../../redux/utilisateur/utilisateur.slice";

export const ProtectedRoute: React.FC<RouteProps> = props => {
  const bConnecte = useSelector(selectConnecte);
  const location = useLocation();

  if (!bConnecte && location.pathname !== "/deconnexion") {
    const renderComponent = () => <Redirect to={{ pathname: "/connexion" }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
