import styled from "styled-components";
import bg from "../../../../../ui/assets/img/hero-bg.png";

export const StyledHero = styled.div`
  margin: 0 -20px 20px;
  height: 500px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 66%;

  @media (max-width: 1024px) {
    height: 450px;
  }
  @media (max-width: 768px) {
    height: 340px;
  }
  @media (max-width: 425px) {
    background-size: 100%;
    height: 520px;
  }
  @media (max-width: 320px) {
    height: 460px;
  }
`;

export const ContainerWrapper = styled.div`
  padding: 80px 20px 0;
  width: 50%;
  @media (max-width: 425px) {
    padding: 20px 20px 0;
    width: 100%;
  }
`;

export const Introduction = styled.div`
  margin: 0 0 20px;
  font-family: "Roboto Regular";
  font-weight: 700;
`;

export const BtnLogin = styled.button`
  padding: 0 20px;
  height: 40px;
  text-transform: uppercase;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.colors.primary.light};
  border: 0;
  border-radius: 0.28571429rem;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;

  &:hover {
    background: ${(props) => props.theme.colors.primary.dark};
  }
`;
