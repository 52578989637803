import React from "react";

import { Grid } from "semantic-ui-react";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { Title } from "../../core/layout/view/title.elements";

import Projets from "./elements/Projets";

const TableauDeBordView = () => (
  <AnimatedView title="Tableau de bord">
    <Main>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Title>Tableau de bord</Title>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8} className="flex-col">
            <Projets title="Projets en cours" iType={1} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} className="flex-col">
            <Projets title="Projets ouverts à valider" iType={2} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Main>
  </AnimatedView>
);

export default TableauDeBordView;
