import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import {
  VotesCharte,
  VoteCharteResultat,
} from "../../../interfaces/votesCharte.interface";
import { votesCharteSucces, votesCharteErreur } from "../voteCharte.slice";
import axios from "axios";

export interface Response {
  votesCharte: [VoteCharteResultat];
}

export const getVotesCharte = (): ThunkAction<
  void,
  VotesCharte,
  unknown,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios
      .get<Response>(`${process.env.REACT_APP_GRAPHQL_URL}/votesCharte`)
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
    const data = response.data;
    dispatch(votesCharteSucces(data.votesCharte));
  } catch (err) {
    dispatch(votesCharteErreur({ sErreur: err.message }));
  }
};
