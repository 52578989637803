import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import { selectConnecte } from "../../../../redux/utilisateur/utilisateur.slice";

import { Main } from "../../../core/layout/view/main.elements";
import AnimatedView from "../../../core/layout/view/view.elements";
import ConenxionForm from "./connexion.form";
import {
  FormContainer,
  WelcomeText,
  Title,
  Version,
} from "./connexion.elements";

const ConnexionView: FunctionComponent = () => {
  const bConnecte = useSelector(selectConnecte);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (bConnecte) {
      setRedirect(!redirect);
    }
  }, [bConnecte, redirect]);

  if (bConnecte) {
    return <Redirect to="/projets" />;
  }

  return (
    <AnimatedView title="Connexion">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>Bienvenue sur votre Espace Utilisateur ADS</Title>

              <FormContainer>
                <WelcomeText>
                  Pour commencer, veuillez vous connecter avec vos identifiants.
                </WelcomeText>

                <ConenxionForm />
              </FormContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Version>version 0.0.1</Version>
      </Main>
    </AnimatedView>
  );
};

export default ConnexionView;
