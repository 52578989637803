import styled from "styled-components";
import { H2 } from "./title.elements";

export const Card = styled.div`
  position: relative;
  flex: 1;
  margin: 0 0 30px;
  padding: 10px 10px 45px;
  background: ${(props) => props.theme.background.paper};
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 5px;
  box-shadow: -2px 2px 5px ${(props) => props.theme.border.light};
`;

export const CardTitle = styled(H2)`
  padding: 5px 0 10px;
  font-size: 20px;
  border-bottom: 1px solid ${(props) => props.theme.border.dark};
`;

export const CardFooter = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
`;
