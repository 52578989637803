import React, { FunctionComponent, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { selectUtilisateur } from "../../../redux/utilisateur/utilisateur.slice";

import { getVoteCharte } from "../../../redux/utilisateur/actions/voteCharte.action";

import { getVotesCharte } from "../../../redux/voteCharte/actions/voteCharte.action";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import ChartElement from "./elements/charte.element";
// import QuestionForm from "./elements/question.form";
import { Title } from "../../core/layout/view/title.elements";

/*import {
  ConteneurAction,
  ButtonAccepter,
  ButtonDiscussion,
  ButtonQuestion,
  ConteneurResultat,
  TitreResulat,
  ListeResulat,
  VoteSuccessNotice,
} from "./charte.elements";*/

const CharteView: FunctionComponent = () => {
  const dispatch = useDispatch();
  /*const [bAfficheFormQuestion, setAfficheFormQuestion] = useState<boolean>(
    false
  );*/
  // const [bVote, setVote] = useState<boolean>(false);

  const utilisateur = useSelector(selectUtilisateur);
  // const tVote = useSelector(selectVotesCharte);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getVoteCharte());
    dispatch(getVotesCharte());
  }, [utilisateur.voteCharte.bVote, dispatch]);

  /*const voteCharte = (bVote: boolean) => {
    setVote(true);
    setTimeout(() => {
      setVote(false);
    }, 3000);
    dispatch(dispatchVoteCharte(utilisateur.identite.iPKUtilisateur, bVote));
  };*/

  return (
    <AnimatedView title="Charte">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>
                Charte du club utilisateur ADS
                {/*<br />
                <Link
                  style={{ fontSize: 18, textDecoration: "underline" }}
                  to="/discussion-autour-de-la-charte"
                >
                  <Icon name="discussions" /> Accéder à la discussion
                </Link>*/}
              </Title>
              <ChartElement />
              {/*
              <ConteneurAction>
                <ButtonAccepter
                  disabled={utilisateur.voteCharte.bVote !== null}
                  onClick={() => voteCharte(true)}
                >
                  J'approuve la charte
                </ButtonAccepter>
                <ButtonQuestion onClick={() => setAfficheFormQuestion(true)}>
                  Poser une question
                </ButtonQuestion>
                <Link to="/discussion-autour-de-la-charte">
                  <ButtonQuestion>
                    <Icon name="discussions" /> Accéder à la discussion
                  </ButtonQuestion>
                </Link>
                {bVote && (
                  <VoteSuccessNotice>
                    Merci, votre approbation a bien été prise en compte.
                  </VoteSuccessNotice>
                )}
              </ConteneurAction>
              {bAfficheFormQuestion && (
                <QuestionForm closeForm={() => setAfficheFormQuestion(false)} />
              )}
              {tVote.filter((v) => v.bVote === true).length > 0 && (
                <ConteneurResultat>
                  <TitreResulat>
                    Structure
                    {tVote.filter((v) => v.bVote === true).length > 1 &&
                      "s"}{" "}
                    ayant approuvé la charte
                  </TitreResulat>
                  <ListeResulat>
                    {tVote
                      .filter((v) => v.bVote === true)
                      .map((v) => (
                        <li key={`accepte-${v.sIdentifiant}`}>
                          {v.sIdentifiant}
                        </li>
                      ))}
                  </ListeResulat>
                </ConteneurResultat>
                      )}*/}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default CharteView;
