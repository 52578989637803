import styled from "styled-components";
import { Link } from "react-router-dom";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Tbody = styled.tbody``;

export const Thead = styled.thead`
  border-bottom: 1px solid ${(props) => props.theme.border.dark};
`;

export const Tr = styled.tr`
  tbody &:nth-child(odd) {
    background: ${(props) => props.theme.background.light};
  }
}

  &:hover td a {
    color: ${(props) => props.theme.colors.secondary.main} !important;
  }
`;

export const TrActions = styled.tr`
  border-top: 1px solid ${(props) => props.theme.border.dark};

  & td {
    padding: 5px 0;
  }
`;

export const Th = styled.th`
  padding: 0 5px;
  text-align: left;
`;

export const ThActions = styled.th`
  padding: 0 5px;
  text-align: left;
  width: 165px !important;
`;

export const Td = styled.td``;

export const TdActions = styled.td`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const TdLink = styled(Link)`
  display: block;
  padding: 5px;
`;
