import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Modal, Form, TextArea } from "semantic-ui-react";

import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";

const BtnSave = styled(ButtonAdd)`
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
`;

class DiscussionAddForm extends Component {
  state = {
    sCorps: "",
  };

  onFieldChange = (evt, data) => {
    this.setState({ [data.id]: data.value });
  };

  render() {
    const { title, open, onClose, onSave, errors } = this.props;
    const { sCorps } = this.state;

    return (
      <Modal
        size="small"
        centered={false}
        dimmer="inverted"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field
                control={TextArea}
                width={16}
                id="sCorps"
                label="Saisissez votre réponse :"
                value={sCorps}
                error={!!errors.sCorps}
                onChange={this.onFieldChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <BtnCancel style={{ marginRight: 10 }} onClick={onClose}>
            Annuler
          </BtnCancel>
          <BtnSave onClick={(evt) => onSave(evt, this.state)}>
            Enregistrer
          </BtnSave>
        </Modal.Actions>
      </Modal>
    );
  }
}

DiscussionAddForm.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DiscussionAddForm;
