import React, { FunctionComponent, useState, useEffect } from "react";
import { ReponseCharteResultat } from "../../../../interfaces/questionCharte.interface";

import ReponseForm from "./reponse.form";
import {
  ConteneurQuestion,
  Titre,
  QuestionContenu,
  ButtonReponse,
  ConteneurReponse,
  ReponseContenu,
} from "../discussion.elements";

interface iProps {
  iPKQuestionCharte: number;
  sIdentifiant: string;
  sQuestion: string;
  tReponse: ReponseCharteResultat[];
  setNouvelleReponse: (event: React.SetStateAction<boolean>) => void;
}

const Discussion: FunctionComponent<iProps> = ({
  setNouvelleReponse,
  iPKQuestionCharte,
  sIdentifiant,
  sQuestion,
  tReponse,
}) => {
  const [bAfficheForm, setAfficheForm] = useState<boolean>(false);
  useEffect(() => {}, [bAfficheForm]);

  return (
    <ConteneurQuestion>
      <Titre>
        Question{" "}
        {["A", "E", "I", "O", "U"].indexOf(sIdentifiant[0]) !== -1
          ? "d'"
          : "de "}
        {sIdentifiant} :
        <ButtonReponse onClick={() => setAfficheForm(true)}>
          Répondre
        </ButtonReponse>
      </Titre>
      <QuestionContenu>{sQuestion}</QuestionContenu>
      {tReponse.map((r) => (
        <ConteneurReponse>
          <Titre>
            Réponse{" "}
            {["A", "E", "I", "O", "U"].indexOf(r.sIdentifiant[0]) !== -1
              ? "d'"
              : "de "}
            {r.sIdentifiant} :
          </Titre>
          <ReponseContenu>{r.sReponse}</ReponseContenu>
        </ConteneurReponse>
      ))}
      <ReponseForm
        iPKQuestionCharte={iPKQuestionCharte}
        closeForm={() => setAfficheForm(false)}
        setNouvelleReponse={setNouvelleReponse}
        bAfficheForm={bAfficheForm}
        setAfficheForm={setAfficheForm}
      />
    </ConteneurQuestion>
  );
};

export default Discussion;
