import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import { Auth, Identite } from "../../interfaces/utilisateur.interface";
import { VoteCharte } from "../../interfaces/votesCharte.interface";

const initialState: Auth = {
  bConnecte: false,
  identite: {
    iPKUtilisateur: 0,
    sIdentifiant: "",
    iFKStructure: null,
    iRole: 0,
    tVote: [],
  },
  voteCharte: {
    bVote: null,
  },
  sErreur: "",
};

export const slice = createSlice({
  name: "utilisateur",
  initialState,
  reducers: {
    connexionSucces: (state, { payload }: PayloadAction<Identite>) => {
      state.bConnecte = true;
      state.identite = payload;
      state.sErreur = "";
    },
    connexionErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.bConnecte = false;
      state.sErreur = "Identifiant ou mot de passe incorrect.";
    },
    deconnexion: (state) => {
      state.bConnecte = false;
      state.identite = {
        iPKUtilisateur: 0,
        iFKStructure: null,
        sIdentifiant: "",
        iRole: 0,
        tVote: [],
      };
      state.sErreur = "";
    },
    voteCharteSucces: (state, { payload }: PayloadAction<VoteCharte>) => {
      state.voteCharte = payload;
      state.sErreur = "";
    },
    voteCharteErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Problème survenu lors de la récupération de votre vote";
    },
  },
});

export const {
  connexionSucces,
  connexionErreur,
  deconnexion,
  voteCharteSucces,
  voteCharteErreur,
} = slice.actions;
export const selectConnecte = (state: Store) => state.utilisateur.bConnecte;
export const selectUtilisateur = (state: Store) => state.utilisateur;
export const selectStoreErreur = (state: Store) => state.utilisateur.sErreur;

export default slice.reducer;
