import { createGlobalStyle } from "styled-components";

import RobotoRegularTTF from "../fonts/Roboto/Roboto-Regular.ttf";

import RobotoLightTTF from "../fonts/Roboto/Roboto-Light.ttf";

import RobotoBoldTTF from "../fonts/Roboto/Roboto-Bold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {font-family: "Roboto Regular";
    src: url("${RobotoRegularTTF}") format("truetype");
  }

  @font-face {font-family: "Roboto Light";
  src: url("${RobotoLightTTF}") format("truetype");
  }

  @font-face {font-family: "Roboto Bold";
  src: url("${RobotoBoldTTF}") format("truetype");
  }

  * {
    box-sizing: border-box;
  }

  html {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.background.paper};
  }

  body {
    margin: 0;
    padding: 0;
    min-width: ${window.innerWidth}px;
    min-height: ${window.innerHeight}px;
    height: auto !important;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-family:"Roboto Regular" !important;
    font-size: 14px;
    line-height: 1.2857142857142857142857142857143em;
    color: ${(props) => props.theme.text.primary} !important;
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(39, 46, 92,0.151) 100%) !important;
    overscroll-behavior: none;
  }

  .grid a {
    color: ${(props) => props.theme.text.primary};
    transition: color 0.25s ease-in-out;
  }
  .grid a:hover {
    color: ${(props) => props.theme.colors.secondary.main};
  }

  label, strong {
    font-family:"Roboto Bold" !important;
  }

  input, optgroup, select, textarea,
  .ui.input>input,
  .ui.form input:not([type]),
  .ui.form input[type=date],
  .ui.form input[type=datetime-local],
  .ui.form input[type=email],
  .ui.form input[type=file],
  .ui.form input[type=number],
  .ui.form input[type=password],
  .ui.form input[type=search],
  .ui.form input[type=tel],
  .ui.form input[type=text],
  .ui.form input[type=time],
  .ui.form input[type=url]
   {
    font-family:"Roboto Regular" !important;
  }

  button {
    font-family:"Roboto Light" !important;
  }

  .flex-col {
    display: flex !important;
    flex-direction: column;
  }

  @media(max-width: 1366px) {
    body {
      font-size: 16px !important;
      line-height: 1.2857142857142857142857142857143em;
    }
  }
`;

export default GlobalStyle;
