import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConnecte,
  selectUtilisateur,
} from "../../../../redux/utilisateur/utilisateur.slice";
import { DispatchDeconnexion } from "../../../../redux/utilisateur/actions/deconnexion.action";

import { Icon } from "semantic-ui-react";

import ADSlogo from "../../../../ui/assets/img/ADSlogo.png";
import {
  Menu,
  MenuItem,
  UserItem,
  Link,
  BtnLogIn,
  Prenom,
  HideOnMobile,
  IconMobile,
  Logo,
} from "./navigation.elements";

export const Navigation: FunctionComponent = () => {
  const bConnecte = useSelector(selectConnecte);
  const utilisateur = useSelector(selectUtilisateur);
  const dispatch = useDispatch();

  return (
    <Menu>
      <MenuItem>
        <Link to="/">
          <Logo src={ADSlogo} alt="logo ads" />
        </Link>
      </MenuItem>
      {bConnecte && (
        <>
          {/*<MenuItem>
            <Link to="/tableau-de-bord">
              <IconMobile name="dashboard" />
              <HideOnMobile>Tableau de bord</HideOnMobile>
            </Link>
          </MenuItem>*/}
          <MenuItem>
            <Link to="/projets">
              <IconMobile name="folder outline" />
              <HideOnMobile>Projets</HideOnMobile>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/agenda">
              <IconMobile name="calendar alternate outline" />
              <HideOnMobile>Agenda</HideOnMobile>
            </Link>
          </MenuItem>
        </>
      )}
      {bConnecte && (
        <UserItem>
          <Icon name="user" />{" "}
          <Prenom>{utilisateur.identite.sIdentifiant}</Prenom>{" "}
          <BtnLogIn to="#" onClick={() => dispatch(DispatchDeconnexion())}>
            <Icon name="power" />
          </BtnLogIn>
        </UserItem>
      )}
    </Menu>
  );
};
