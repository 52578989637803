import styled from "styled-components";
import { H1 } from "../../../core/layout/view/title.elements";

export const Title = styled(H1)`
  padding: 40px 0 0;
`;

export const ConnexionFormWrapper = styled.div`
  width: 400px;
`;

export const Logo = styled.img`
  display: block;
  margin: 40px auto 0;
  width: 50%;
  height: auto;
  animation: App-logo-float infinite 3s ease-in-out;
`;

export const AppName = styled.h1`
  margin: 40px 0;
  height: 30px;
  font-family: "UniversCondensedBold";
  font-size: 60px;
  text-align: center;
`;

export const FormContainer = styled.div`
  margin: 100px auto 0;
  width: 300px;
  padding: 20px;
  background: ${(props) => props.theme.background.paper};
`;

export const WelcomeText = styled.p`
  margin: 0 0 20px;
`;

export const AppNameEasy = styled.span`
  font-weight: normal;
`;

export const AppNameStock = styled.span`
  font-weight: bold;
`;

export const Version = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  cursor: default;
`;
