import styled from "styled-components";

export const H1 = styled.h1`
  font-family: "Roboto Regular";
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.dark};
`;

export const H2 = styled.h2`
  font-family: "Roboto Light";
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary.dark};
`;

export const H3 = styled.h3`
  font-family: "Roboto Regular";
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary.dark};
`;

export const Title = styled(H1)`
  padding-top: 40px;
  padding-bottom: 20px;
  height: 20px;
  & > button {
    vertical-align: middle;
  }
`;
