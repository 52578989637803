import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { ReponseCharteResultat } from "../../../interfaces/reponseCharte.interface";
import {
  reponseCharteSucces,
  reponseCharteErreur,
} from "../reponseCharte.slice";
import axios from "axios";
import { Utilisateur } from "../../../interfaces/utilisateur.interface";

export interface Response {
  reponsesCharte: ReponseCharteResultat[];
}

export const dispatchReponseCharte = (
  iFKUtilisateur: number,
  iFKQuestionCharte: number,
  sReponse: string
): ThunkAction<void, Utilisateur, unknown, Action<string>> => async (
  dispatch
) => {
  try {
    const response = await axios.post<Response>(
      `${process.env.REACT_APP_GRAPHQL_URL}/charte/insertReponse`,
      {
        iFKUtilisateur,
        iFKQuestionCharte,
        sReponse,
      }
    );
    const data = response.data;

    dispatch(reponseCharteSucces(data.reponsesCharte));
  } catch (err) {
    dispatch(reponseCharteErreur({ sErreur: err }));
  }
};
