import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { DateTime } from "luxon";

import { selectUtilisateur } from "../../../../../redux/utilisateur/utilisateur.slice";

import MessageForm from "../Form";
import Confirm from "../../../../core/layout/view/confirm.elements";
import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";

import { Message } from "../../../../../interfaces/projet.interface";

import {
  Titre,
  ConteneurReponse,
  ReponseContenu,
  ListeQuestion,
} from "../../../discussionCharte/discussion.elements";

const BtnMessageAdd = styled(ButtonAdd)`
  margin-right: 15px;
`;

interface Reponse {
  message: Message;
}

interface Props {
  messages: any[];
  iFKDiscussion: number;
  bFull?: boolean;
  display: boolean;
}

const MessageList: FunctionComponent<Props> = ({
  messages,
  iFKDiscussion,
  display,
}: Props) => {
  const [addMessageModal, setAddMessageModal] = useState<boolean>(false);
  const [deleteMessageModal, setDeleteMessageModal] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(null);
  const [tMessage, setMessages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const utilisateur = useSelector(selectUtilisateur);
  const isAdmin = utilisateur.identite.iRole === 4;

  useEffect(() => {
    if (!tMessage || (tMessage && tMessage.length === 0)) {
      setMessages(messages);
    }
  }, [messages, tMessage]);

  const onCloseMessageAddForm = () => {
    setAddMessageModal(false);
    setDeleteMessageModal(false);
    setMessage(null);
  };

  const onCloseMessageDeleteForm = () => {
    setAddMessageModal(false);
    setDeleteMessageModal(false);
    setMessage(null);
  };

  const onBtnAddClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddMessageModal(true);
    setDeleteMessageModal(false);
  };

  const onMessageAddClick = async (evt: any, message: any) => {
    evt.preventDefault();

    const { sCorps } = message;

    if (!sCorps) {
      errors.sCorps = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const response = await axios.post<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/message`,
      {
        sTitre: "",
        sCorps,
        iFKUtilisateur: utilisateur.identite.iPKUtilisateur,
        iFKDiscussion,
      }
    );
    if (response.data.message) {
      tMessage.unshift(response.data.message);
      setMessages(tMessage);
      onCloseMessageAddForm();
    }
  };

  const onBtnDeleteClick = (evt: any, message: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddMessageModal(false);
    setDeleteMessageModal(true);
    setMessage(message);
  };

  const onMessageDeleteClick = async (evt: any, message: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    const { iPKMessage } = message;
    const response = await axios.delete<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/message`,
      {
        data: { iPKMessage },
      }
    );
    if (response.data.message) {
      setMessages(
        tMessage.filter(
          (d: any) => d.iPKMessage !== response.data.message.iPKMessage
        )
      );
      onCloseMessageDeleteForm();
    }
  };
  // if (display) {
  return (
    <>
      <BtnMessageAdd style={{ margin: "10px 5px" }} onClick={onBtnAddClick}>
        Poser une question/répondre
      </BtnMessageAdd>
      {tMessage ? (
        <ListeQuestion style={{ marginTop: 0 }}>
          {tMessage.map((m) => (
            <ConteneurReponse style={{ margin: "0 50px" }} key={m.iPKMessage}>
              <Titre>
                Le {DateTime.fromISO(m.dDate).toFormat("dd/LL/yyyy à HH:mm")}{" "}
                par {m.Utilisateur.sIdentifiant}
                {isAdmin ||
                m.iFKUtilisateur === utilisateur.identite.iPKUtilisateur ? (
                  <ButtonDelete
                    style={{ float: "right", marginTop: 15 }}
                    onClick={(evt) => onBtnDeleteClick(evt, m)}
                  >
                    Supprimer
                  </ButtonDelete>
                ) : (
                  ""
                )}
              </Titre>
              <ReponseContenu>{m.sCorps}</ReponseContenu>
            </ConteneurReponse>
          ))}
        </ListeQuestion>
      ) : (
        <p>Aucun message...</p>
      )}
      {addMessageModal && (
        <MessageForm
          title="Ajouter une réponse"
          errors={errors}
          open={addMessageModal}
          onSave={onMessageAddClick}
          onClose={onCloseMessageAddForm}
        />
      )}
      {deleteMessageModal && (
        <Confirm
          message="Souhaitez-vous supprimer ce message ?"
          onConfirm={(evt) => onMessageDeleteClick(evt, message)}
          onCancel={onCloseMessageDeleteForm}
        />
      )}
    </>
  );
  // }
};

export default MessageList;
