import styled from "styled-components";
import { Link } from "react-router-dom";

export const List = styled.ul`
  margin: 0 0 20px;
  padding: 0;
`;

export const ListItem = styled.li`
  list-style: none;
  line-height: 30px;

  &:nth-child(odd) {
    background: ${(props) => props.theme.background.light};
  }
`;

export const ListItemLink = styled(Link)`
  display: block;
  padding: 0 5px;
`;
