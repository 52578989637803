import React from "react";
import styled from "styled-components";

import { Navigation } from "../navigation/navigation.element";

const AppHeader = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  background: ${(props) => props.theme.background.paper};
  border-bottom: 1px solid ${(props) => props.theme.border.light};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const PageNavigationContainer = styled.nav`
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
  border-left: 1px solid ${(props) => props.theme.border.light};
  @media (max-width: 1366px) {
    width: 100%;
  }
`;

function Header() {
  return (
    <AppHeader>
      <PageNavigationContainer>
        <Navigation />
      </PageNavigationContainer>
    </AppHeader>
  );
}

export default Header;
