import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";

const initialState = {
  tProjet: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "projet",
  initialState,
  reducers: {
    projetSucces: (state, { payload }) => {
      state.tProjet = payload;
      state.sErreur = "";
    },
    projetErreur: (state, { payload }: PayloadAction<{ sErreur?: string }>) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { projetSucces, projetErreur } = slice.actions;
export const selectProjet = (state: Store) => state.projet.tProjet;
export const selectStoreErreur = (state: Store) => state.projet.sErreur;

export default slice.reducer;
