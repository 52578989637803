import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";

const StyledFooter = styled.div`
  padding: 20px;
  width: 100%;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.colors.primary.dark};
  @media (max-width: 425px) {
    font-size: 13px;
  }
`;

const PageFooterContainer = styled.nav`
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
  @media (max-width: 1366px) {
    width: 100%;
  }
`;

const Separateur = styled.span`
  margin: 0 10px;
`;

const FooterLink = styled(NavLink)`
  color: white !important;
  &:hover {
    color: ${(props) => props.theme.colors.primary.light} !important;
  }
`;

const Site = styled.div``;

const Copyright = styled.div`
  text-align: right;
`;

const Footer = () => (
  <StyledFooter>
    <PageFooterContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={10} tablet={8} computer={8}>
            <Site>
              Le club des utilisateurs ADS <Separateur>|</Separateur>{" "}
              <FooterLink to="/charte">Charte</FooterLink>
            </Site>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={8} computer={8}>
            <Copyright>&copy; ADS {new Date().getFullYear()}</Copyright>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageFooterContainer>
  </StyledFooter>
);

export default Footer;
