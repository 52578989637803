import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

// import logger from "redux-logger";

import utilisateurReducer from "./redux/utilisateur/utilisateur.slice";
import votesCharteReducer from "./redux/voteCharte/voteCharte.slice";
import questionsCharteReducer from "./redux/questionCharte/questionCharte.slice";
import evenementReducer from "./redux/evenement/evenement.slice";
import projetReducer from "./redux/projet/projet.slice";
import discussionReducer from "./redux/discussion/discussion.slice";

const reducer = combineReducers({
  utilisateur: utilisateurReducer,
  votesCharte: votesCharteReducer,
  questionsCharte: questionsCharteReducer,
  evenement: evenementReducer,
  projet: projetReducer,
  discussion: discussionReducer,
});

const middleware = [...getDefaultMiddleware()];

export default configureStore({
  reducer,
  middleware,
});
