import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { Modal, Form, Input, Select, TextArea } from "semantic-ui-react";

// import { listProjects } from "../../../redux/actions/project";

import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";

const BtnSave = styled(ButtonAdd)`
  margin: 0 0 0 15px;
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
`;

class RequestForm extends Component {
  state = {
    iPKDemande: -1,
    iFKProjet: -1,
    sLibelle: "",
    sDescription: "",
    iPriorite: -1,
    iDuree: -1,
    iFKProjetOptions: [],
  };

  iPrioriteOptions = [
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 3, text: "3", value: 3 },
    { key: 4, text: "4", value: 4 },
    { key: 5, text: "5", value: 5 },
  ];

  iDureeOptions = [
    { key: 1, text: "< 1 mois", value: 1 },
    { key: 2, text: "< 3 mois", value: 2 },
    { key: 3, text: "< 6 mois", value: 3 },
    { key: 4, text: "< 1 an", value: 4 },
    { key: 5, text: "< 2 ans", value: 5 },
  ];

  constructor(props) {
    super(props);

    const { request, project } = this.props;

    if (request) {
      this.state = {
        iPKDemande: request.iPKDemande,
        iFKProjet: request.iFKProjet,
        sLibelle: request.sLibelle,
        sDescription: request.sDescription,
        iPriorite: request.iPriorite,
        iDuree: request.iDuree,
      };
    } else if (project) {
      this.state = {
        iPKDemande: -1,
        iFKProjet: project.iPKProjet,
        sLibelle: "",
        sDescription: "",
        iPriorite: -1,
        iDuree: -1,
      };
    }
  }

  componentDidMount() {
    // const { dispatchListProjects } = this.props;
    // dispatchListProjects();
  }

  componentDidUpdate(prevProps) {
    const { projects } = this.props;

    if (prevProps.projects !== projects) {
      const iFKProjetOptions = [];
      projects.forEach((p) => {
        iFKProjetOptions.push({
          key: p.iPKProjet,
          text: p.sLibelle,
          value: p.iPKProjet,
        });
      });

      this.setState({ iFKProjetOptions });
    }
  }

  onFieldChange = (evt, data) => {
    this.setState({ [data.id]: data.value });
  };

  render() {
    const { title, open, onClose, onSave } = this.props;
    const {
      iFKProjet,
      sLibelle,
      sDescription,
      iPriorite,
      iDuree,
      iFKProjetOptions,
    } = this.state;

    return (
      <Modal
        size="small"
        centered={false}
        dimmer="inverted"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                width={16}
                id="iFKProjet"
                label="Projet"
                options={iFKProjetOptions}
                value={iFKProjet}
                onChange={this.onFieldChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                width={16}
                id="sLibelle"
                label="Libelle"
                value={sLibelle}
                onChange={this.onFieldChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={TextArea}
                width={16}
                id="sDescription"
                label="Description"
                value={sDescription}
                onChange={this.onFieldChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                width={16}
                id="iPriorite"
                label="Priorité"
                options={this.iPrioriteOptions}
                value={iPriorite}
                onChange={this.onFieldChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                width={16}
                id="iDuree"
                label="Durée"
                options={this.iDureeOptions}
                value={iDuree}
                onChange={this.onFieldChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <BtnCancel onClick={onClose}>Annuler</BtnCancel>
          <BtnSave onClick={(evt) => onSave(evt, this.state)}>
            Enregistrer
          </BtnSave>
        </Modal.Actions>
      </Modal>
    );
  }
}

RequestForm.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  request: PropTypes.objectOf(PropTypes.any),
  project: PropTypes.objectOf(PropTypes.any),
  dispatchListProjects: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

RequestForm.defaultProps = {
  request: null,
  project: null,
};

const mapStateToProps = (state) => ({
  projects: state.projet.tProjet,
});

export default connect(mapStateToProps)(RequestForm);
