import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import styled from "styled-components";

import { Grid } from "semantic-ui-react";

// import { listProjects } from "../../redux/actions/project";
// import { listEvents } from "../../redux/actions/event";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { Title } from "../../core/layout/view/title.elements";
import { Card, CardTitle } from "../../core/layout/view/card.elements";

import { Evenement, Projet } from "../../../interfaces/projet.interface";

import EventList from "./elements/List";
import bg from "../../../ui/assets/img/agenda.png";
const StyledAnimatedView = styled(AnimatedView)`
  background: url(${bg}) 100% 50% no-repeat;
`;

interface ReponseEvenement {
  tEvenement: Evenement[];
}

interface ReponseProjet {
  tProjet: Projet[];
}

const EvenementsView: FunctionComponent = () => {
  const [tEvenement, setEvenements] = useState<any[]>([]);
  const [tProjet, setProjets] = useState<any[]>([]);

  const listEvenement = useCallback(async () => {
    const response = await axios.get<ReponseEvenement>(
      `${process.env.REACT_APP_GRAPHQL_URL}/evenements`
    );
    setEvenements(response.data.tEvenement);
  }, []);

  const listProjet = useCallback(async () => {
    const response = await axios.get<ReponseProjet>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projets`
    );
    setProjets(response.data.tProjet);
  }, []);

  useEffect(() => {
    listEvenement();
    listProjet();
  }, [listEvenement, listProjet]);
  return (
    <StyledAnimatedView title="Agenda">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>Agenda</Title>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              className="flex-col"
            >
              <Card>
                <CardTitle>Liste des rendez-vous</CardTitle>

                <EventList events={tEvenement} projects={tProjet} />
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </StyledAnimatedView>
  );
};

export default EvenementsView;
