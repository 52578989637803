import React from "react";

import { Icon } from "semantic-ui-react";

import styled from "styled-components";

import { Input } from "./form.elements";

const Conteneur = styled.div`
  display: inherit !important;
`;

const FormInput = styled(Input)``;

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  placeholder: string;
  type: string;
  className?: string;
}

const PasswordInput = ({
  value,
  onChange,
  className,
  id,
  placeholder,
  type,
}: Props) => {
  return (
    <Conteneur className="ui icon input">
      <FormInput
        className={className}
        type={type}
        id={id}
        placeholder={placeholder}
        autoComplete="password"
        value={value}
        width={16}
        onChange={onChange}
      />
      <Icon
        id={`icon${id}`}
        name={type === "text" ? "eye slash" : "eye"}
        onClick={() => {
          const pwd = document.getElementById(id);
          const iconPassword = document.getElementById(`icon${id}`);
          if (pwd && pwd.getAttribute("type") === "text") {
            iconPassword && iconPassword.classList.remove("slash");
            pwd.setAttribute("type", "password");
          } else {
            iconPassword && iconPassword.classList.add("slash");
            pwd && pwd.setAttribute("type", "text");
          }
        }}
        link
      />
    </Conteneur>
  );
};

export default PasswordInput;
