import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled.button`
  padding: 0 20px;
  height: 40px;
  text-transform: uppercase;
  color: ${(props) => props.theme.button.primary.text};
  background: ${(props) => props.theme.colors.primary.light};
  border: 0;
  border-radius: 0.28571429rem;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;
  &:disabled {
    background: ${(props) => props.theme.action.disabled};
    cursor: default;
    &:hover {
      background: ${(props) => props.theme.action.disabled};
    }
  }
  &:hover {
    background: ${(props) => props.theme.colors.primary.dark};
  }
`;

export const ButtonAdd = styled(Button)`
  margin: 0;
  padding: 0 10px;
  height: 20px;
  font-size: 12px;

  &:hover {
    background: ${(props) => props.theme.colors.primary.dark};
  }
`;

export const ButtonEdit = styled(Button)`
  padding: 0 10px;
  height: 20px;
  font-size: 12px;

  color: ${(props) => props.theme.background.paper};
  background: ${(props) => props.theme.colors.secondary.main};

  &:hover {
    background: ${(props) => props.theme.colors.secondary.hover};
  }
`;

export const ButtonDelete = styled(Button)`
  margin: 0 0 0 15px;
  padding: 0 10px;
  height: 20px;
  font-size: 12px;
  background: ${(props) => props.theme.colors.error.main};

  &:hover {
    background: ${(props) => props.theme.colors.error.hover};
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background: #ccc;
  border: 0;
  cursor: pointer;
`;
