import React, { FunctionComponent, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// import { listProjects } from "../../../redux/actions/project";

import {
  List,
  ListItem,
  ListItemLink,
} from "../../../../core/layout/view/list.elements";
import {
  Card,
  CardTitle,
  CardFooter,
} from "../../../../core/layout/view/card.elements";
import { Button } from "../../../../core/layout/button/button.elements";

interface Props {
  title: string;
  iType: number;
}

const Projects: FunctionComponent<Props> = ({ title, iType }: Props) => {
  const [projects, setProjects] = useState<any[]>([]);

  useEffect(() => {
    const listProjets = async () => {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_GRAPHQL_URL}/projets`
      );
      setProjects(response.data.tProjet);
    };
    listProjets();
  }, []);

  return (
    <Card>
      <CardTitle>{title}</CardTitle>

      {projects && (
        <List>
          {projects
            .filter((p) => p.iType === iType)
            .map((project) => (
              <ListItem key={project.iPKProjet}>
                <ListItemLink
                  to={`/projets/${project.iPKProjet}/${project.sLibelle}`}
                >
                  {project.sLibelle}
                </ListItemLink>
              </ListItem>
            ))}
        </List>
      )}

      <CardFooter>
        <Link to={iType === 2 ? "/projets-a-valider" : "/projets-en-cours"}>
          <Button onClick={() => {}}>Accéder aux projets</Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default Projects;
