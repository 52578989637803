import React from "react";
import { ThemeProvider } from "styled-components";

import GlobalStyle from "./ui/assets/css/global-css";
import "semantic-ui-css/semantic.min.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./components/core/routes/ProtectedRoute";

import AnimatedSwitch from "./components/core/routes/AnimatedSwitch";
import Header from "./components/core/layout/view/header.elements";
import Footer from "./components/core/layout/view/footer.elements";
import ConnexionView from "./components/views/utilisateur/connexion/connexion.view";
import DeconnexionView from "./components/views/utilisateur/deconnexion/deconnexion.view";
import AccueilView from "./components/views/accueil/accueil.view";
import CharteView from "./components/views/charte/charte.view";
import DiscussionCharteView from "./components/views/discussionCharte/discussionCharte.view";
import ProjetsAValiderView from "./components/views/projets/projetsAValider.view";
import ProjetsEnCoursView from "./components/views/projets/projetsEnCours.view";
import ProjetsView from "./components/views/projets/projets.view";
import ProjetView from "./components/views/projets/projet.view";
import EvenementsView from "./components/views/agenda/evenements.view";
import EvenementView from "./components/views/agenda/evenement.view";
import DiscussionView from "./components/views/discussion/discussion.view";
import DiscussionsView from "./components/views/discussion/discussions.view";
import TableauDeBordView from "./components/views/tableauDeBord/tableauDeBord.view";

const theme = require(`./ui/${process.env.REACT_APP_THEME}.theme.ts`);

function App() {
  return (
    <ThemeProvider theme={theme.default}>
      <Router>
        <Header />
        <AnimatedSwitch>
          <Route path="/" exact component={AccueilView} />
          <ProtectedRoute
            path="/tableau-de-bord"
            exact
            component={TableauDeBordView}
          />
          <ProtectedRoute path="/projets" exact component={ProjetsView} />
          <ProtectedRoute
            path="/projets-en-cours"
            exact
            component={ProjetsEnCoursView}
          />
          <ProtectedRoute
            path="/projets-a-valider"
            exact
            component={ProjetsAValiderView}
          />
          <ProtectedRoute
            path="/projets/:iPKProjet/:sLibelle"
            exact
            component={ProjetView}
          />
          <ProtectedRoute
            path="/discussion/:iPKDiscussion/:sLibelle"
            exact
            component={DiscussionView}
          />
          <ProtectedRoute
            path="/discussions/"
            exact
            component={DiscussionsView}
          />
          <ProtectedRoute path="/agenda" exact component={EvenementsView} />
          <ProtectedRoute
            path="/agenda/:iPKEvenement/:sLibelle"
            exact
            component={EvenementView}
          />
          <ProtectedRoute path="/charte" exact component={CharteView} />
          <ProtectedRoute
            path="/discussion-autour-de-la-charte"
            exact
            component={DiscussionCharteView}
          />
          <Route path="/connexion" exact component={ConnexionView} />
          <Route path="/deconnexion" exact component={DeconnexionView} />
        </AnimatedSwitch>
        <Footer />
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
