import styled from "styled-components";
import { Button } from "../../core/layout/button/button.elements";

export const ListeQuestion = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ConteneurQuestion = styled.li`
  width: 60%;
  margin-bottom: 15px;
  @media (max-width: 425px) {
    width: 100%;
  }
  white-space: pre-wrap;
`;

export const ConteneurReponse = styled.li`
  margin-left: 50px;
  white-space: pre-wrap;
`;

export const Titre = styled.div`
  height: 50px;
  line-height: 50px;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
`;

export const QuestionContenu = styled.div`
  padding: 5px;
  font-weight: bold;
`;

export const ReponseContenu = styled.div`
  padding: 5px;
`;

export const ButtonReponse = styled(Button)`
  margin-right: 10px;
  float: right;
`;
