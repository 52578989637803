import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

import { Grid } from "semantic-ui-react";

import { Title } from "../../core/layout/view/title.elements";
import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";

import "moment/locale/fr";

import MessageList from "../message/elements/List";

interface Props {
  match: any;
}

const DiscussionView: FunctionComponent<Props> = ({ match }: Props) => {
  const [discussion, setDiscussion] = useState<any>({});

  const listMessage = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/discussion/${match.params.iPKDiscussion}`
    );
    setDiscussion(response.data.discussion);
  }, [match.params.iPKDiscussion]);

  useEffect(() => {
    listMessage();
  }, [listMessage]);

  return (
    <AnimatedView title="Discussion">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>{discussion.sLibelle}</Title>
              <p>{discussion.sDescription}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <MessageList
                display={true}
                iFKDiscussion={discussion.iPKDiscussion}
                messages={discussion.tMessage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default DiscussionView;
