import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import {
  VoteCharteResultat,
  VotesCharte,
} from "../../interfaces/votesCharte.interface";

const initialState: VotesCharte = {
  tVote: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "votesCharte",
  initialState,
  reducers: {
    votesCharteSucces: (
      state,
      { payload }: PayloadAction<[VoteCharteResultat]>
    ) => {
      state.tVote = payload;
      state.sErreur = "";
    },
    votesCharteErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { votesCharteSucces, votesCharteErreur } = slice.actions;
export const selectVotesCharte = (state: Store) => state.votesCharte.tVote;
export const selectStoreErreur = (state: Store) => state.votesCharte.sErreur;

export default slice.reducer;
