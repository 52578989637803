import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Modal, Form, Input, TextArea, Select } from "semantic-ui-react";

import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";

const BtnSave = styled(ButtonAdd)`
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
`;

class DiscussionAddForm extends Component {
  state = {
    iPKDiscussion: 0,
    iFKUtilisateur: null,
    iFKProjet: null,
    sLibelle: "",
    sDescription: "",
  };

  constructor(props) {
    super(props);

    const { discussion } = this.props;

    if (discussion) {
      this.state = {
        iPKDiscussion: discussion.iPKDiscussion,
        sLibelle: discussion.sLibelle,
        sDescription: discussion.sDescription,
        iFKProjet: discussion.iFKProjet,
      };
    }
  }

  onFieldChange = (evt, data) => {
    this.setState({ [data.id]: data.value });
  };

  render() {
    const { title, open, onClose, onSave, projects, errors } = this.props;
    const { sLibelle, sDescription, iFKProjet } = this.state;

    return (
      <Modal
        size="small"
        centered={false}
        dimmer="inverted"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field
                id="iFKProjet"
                control={Select}
                options={projects.map((x) => {
                  return {
                    key: x.iPKProjet,
                    text: x.sLibelle,
                    value: x.iPKProjet,
                  };
                })}
                label="Projet"
                placeholder="Choisissez dans la liste"
                search
                onChange={this.onFieldChange}
                onSearchChange={this.onFieldChange}
                searchInput={{ id: "iFKProjet" }}
                value={iFKProjet}
                error={!!errors.iFKProjet}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                width={16}
                id="sLibelle"
                label="Libelle"
                value={sLibelle}
                onChange={this.onFieldChange}
                error={!!errors.sLibelle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={TextArea}
                width={16}
                id="sDescription"
                label="Description"
                value={sDescription}
                onChange={this.onFieldChange}
                error={!!errors.sDescription}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <BtnCancel style={{ marginRight: 10 }} onClick={onClose}>
            Annuler
          </BtnCancel>
          <BtnSave onClick={(evt) => onSave(evt, this.state)}>
            Enregistrer
          </BtnSave>
        </Modal.Actions>
      </Modal>
    );
  }
}

DiscussionAddForm.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  discussion: PropTypes.objectOf(PropTypes.any),
  projects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

DiscussionAddForm.defaultProps = {
  discussion: null,
};

export default DiscussionAddForm;
