import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { DateTime } from "luxon";
import axios from "axios";

import { Grid } from "semantic-ui-react";

import { Title } from "../../core/layout/view/title.elements";
import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";

import "moment/locale/fr";

interface Props {
  match: any;
}

const EvenementView: FunctionComponent<Props> = ({ match }: Props) => {
  const [evenement, setEvenement] = useState<any>({});

  const getEvenement = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/evenement/${match.params.iPKEvenement}`
    );
    setEvenement(response.data.evenement);
  }, [match.params.iPKEvenement]);

  useEffect(() => {
    getEvenement();
  }, [getEvenement]);

  return (
    <AnimatedView title="Agenda">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>{evenement.sLibelle}</Title>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <p>{evenement.sInformation}</p>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={6}>
              <div>
                {" "}
                <p>
                  <strong>Projet : </strong>
                  {evenement.Projet && evenement.Projet.sLibelle}
                </p>
                <p>
                  <strong>Prévu le : </strong>
                  {DateTime.fromISO(evenement.dDebut).toFormat(
                    "dd/LL/yyyy"
                  )}{" "}
                  entre {DateTime.fromISO(evenement.dDebut).toFormat("HH:mm")}{" "}
                  et {DateTime.fromISO(evenement.dFin).toFormat("HH:mm")}
                </p>
                <p>
                  <strong>Lieu : </strong>
                  {evenement.sLieu}
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default EvenementView;
