import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";

import { Grid, Icon, Modal, Button, Radio, Loader } from "semantic-ui-react";
import {
  ScatterChart,
  Scatter,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Label,
  ReferenceLine,
} from "recharts";

import { Projet } from "../../../interfaces/projet.interface";

// import { loadProject } from "../../../redux/actions/project";

import { Title } from "../../core/layout/view/title.elements";
import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import { Card, CardTitle } from "../../core/layout/view/card.elements";
import { ButtonEdit } from "../../core/layout/button/button.elements";

import ProjectForm from "./elements/Form";
import EventList from "../agenda/elements/List";
import RequestList from "./elements/RequestList";
import DiscussionList from "../discussion/elements/List";

import { selectUtilisateur } from "../../../redux/utilisateur/utilisateur.slice";

import bg from "../../../ui/assets/img/projet.png";

const VoteOptions = styled.div`
  text-align: center;
`;

const Download = styled.a`
  cursor: pointer;
  margin: 0 0 15px;
  display: inline-block;
  width: 100%;
  padding: 15px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 5px;
  box-shadow: -2px 2px 5px rgb(222, 222, 222);
`;

const StyledAnimatedView = styled(AnimatedView)`
  background: url(${bg}) 100% 50% no-repeat;
`;

interface Props {
  match: any;
}

interface Reponse {
  projet: Projet;
}

const Project: FunctionComponent<Props> = ({ match }: Props) => {
  const [project, setProject] = useState<any>(null);
  const [projectLoaded, setProjectLoaded] = useState<any>(false);
  const [editProjectModal, setEditProjectModal] = useState<boolean>(false);
  // const [errors, setErrors] = useState<any>({});
  const [structure, setStructure] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bVote, setBVote] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  // const [bChangeVote, setbChangeVote] = useState<boolean>(false);
  const [bPilote, setbPilote] = useState<boolean>(false);
  const utilisateur = useSelector(selectUtilisateur);
  const isAdmin = utilisateur.identite.iRole === 4;
  const isDirection = utilisateur.identite.iRole === 3;
  const isDSI = utilisateur.identite.iRole === 2;

  const getProjet = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projet/${match.params.iPKProjet}`
    );
    setProject(response.data.projet);
    const userVote = response.data.projet.tVote.filter(
      (v: any) => v.iFKUtilisateur === utilisateur.identite.iPKUtilisateur
    )[0];
    if (userVote) {
      setBVote(!!userVote.bVote);
      setbPilote(userVote.bPilote);
    }
    setProjectLoaded(true);
  }, [match.params.iPKProjet, utilisateur.identite.iPKUtilisateur]);

  const insertVote = async (bVote: boolean) => {
    setDisabled(true);
    await axios.post<any>(`${process.env.REACT_APP_GRAPHQL_URL}/vote`, {
      iFKUtilisateur: utilisateur.identite.iPKUtilisateur,
      iFKProjet: Number(match.params.iPKProjet),
      bVote,
    });
    setBVote(bVote);
    getProjet();
    setDisabled(false);
    setOpenModal(false);
  };

  const editVote = async (bVote: boolean) => {
    setDisabled(true);
    await axios.put<any>(`${process.env.REACT_APP_GRAPHQL_URL}/vote`, {
      iFKUtilisateur: utilisateur.identite.iPKUtilisateur,
      iFKProjet: Number(match.params.iPKProjet),
      bVote: bVote,
      bPilote: bPilote,
    });
    setBVote(bVote);
    getProjet();
    setDisabled(false);
    setOpenModal(false);
  };

  const insertbPilote = async (e: any, data: any) => {
    await axios.put<any>(`${process.env.REACT_APP_GRAPHQL_URL}/vote`, {
      iFKUtilisateur: utilisateur.identite.iPKUtilisateur,
      iFKProjet: Number(match.params.iPKProjet),
      bVote: bVote,
      bPilote: data.checked,
    });
    setbPilote(data.checked);
  };

  const listStructure = useCallback(async () => {
    const response = await axios.get<any>(
      `${process.env.REACT_APP_GRAPHQL_URL}/structures`
    );
    if (utilisateur.identite.iFKStructure) {
      setStructure(
        response.data.tStructure.filter(
          (s: any) =>
            parseInt(s.iPKStructure, 10) === utilisateur.identite.iFKStructure,
          10
        )[0]
      );
    }
  }, [utilisateur.identite.iFKStructure]);

  const onProjectEditClick = async (evt: any, project: any) => {
    evt.preventDefault();

    const {
      iPKProjet,
      sLibelle,
      sDescription,
      sIntroduction,
      sAvancement,
      sAideUrl,
      sDocUrl,
      iDuree,
      fBudget,
      dLivraisonSouhaitee,
      tDocument,
      tStructure,
    } = project;
    const response = await axios.put<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/projet`,
      {
        iPKProjet,
        sLibelle,
        sDescription,
        sIntroduction,
        sAvancement,
        sAideUrl,
        sDocUrl,
        iDuree: Number(iDuree),
        fBudget: Number(fBudget),
        dLivraisonSouhaitee,
        tDocument,
        tStructure,
      }
    );
    if (response.data.projet) {
      setProject(response.data.projet);

      setEditProjectModal(false);
    }
  };

  useEffect(() => {
    getProjet();
    listStructure();
  }, [getProjet, listStructure]);

  const data: any[] = [];
  project &&
    project.tDemande &&
    project.tDemande.forEach((demande: any) => {
      data.push({
        x: demande.iDuree,
        y: demande.iPriorite,
        z: demande.sLibelle,
      });
    });

  data.sort((a, b) => a.x - b.x);

  const userVote =
    project &&
    project.tVote.filter(
      (v: any) => v.iFKUtilisateur === utilisateur.identite.iPKUtilisateur
    )[0];

  let cumule = 0;
  let budgetStructure = 0;

  if (project && project.tStructure) {
    project.tStructure.forEach((s: any) => {
      cumule += s.fPartCout;
    });

    const budgetRestant = (project.fBudget * (100 - cumule)) / 100;
    if (budgetRestant > 0 && structure) {
      const ratioGlobal = 100 / cumule;
      const ratioStructure = 100 / structure.fPartCout / ratioGlobal;
      budgetStructure = budgetRestant / ratioStructure;
    }
  }

  if (!projectLoaded) {
    return <Loader style={{marginTop: 25}} active />;
  }

  return (
    project && (
      <StyledAnimatedView title="Projet">
        <Main>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Title>
                  {project.sLibelle}{" "}
                  {isAdmin && (
                    <ButtonEdit
                      onClick={() => {
                        setEditProjectModal(true);
                      }}
                    >
                      Editer
                    </ButtonEdit>
                  )}
                </Title>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {project.sIntroduction}
                </p>
                <p style={{ whiteSpace: "pre-wrap" }}>{project.sDescription}</p>
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={6}>
                {project.tDocument.map((document: any) => (
                  <>
                    <Download
                      target="_blank"
                      href={`${process.env.REACT_APP_FILE_URL}/${document.sUrl}`}
                    >
                      <Icon name="download" />
                      {document.sLibelle.charAt(0).toUpperCase() +
                        document.sLibelle.slice(1)}
                    </Download>
                    <br />
                  </>
                ))}
                {project.sAideUrl && (
                  <Download target="_blank" href={project.sAideUrl}>
                    <Icon name="question circle" />
                    Accéder à l'aide en ligne
                  </Download>
                )}
                {project.sDocUrl && (
                  <Download target="_blank" href={project.sDocUrl}>
                    <Icon name="question circle" />
                    Accéder à la documentation en ligne
                  </Download>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {(isDSI || isDirection || isAdmin) && (
                  <div>
                    <p>
                      <b>Coût global :</b>{" "}
                      {project.fBudget === 0
                        ? "En cours d'évaluation"
                        : `${project.fBudget} €`}
                    </p>
                    {project.iType === 2 && project.iDuree > 0 && (
                      <p>
                        <b>Durée estimée :</b> {project.iDuree} mois
                      </p>
                    )}
                    {project.iType === 2 &&
                      !isAdmin &&
                      structure &&
                      project.fBudget !== 0 &&
                      project.iDuree > 0 &&
                      project.tStructure.find(
                        (s: any) => s.iPKStructure === structure.iPKStructure
                      ) && (
                        <p>
                          <b>Votre participation : </b>
                          {project.fBudget !== 0 &&
                            `${Math.floor(
                              (project.fBudget * structure.fPartCout) / 100 +
                                budgetStructure
                            )} €`}
                          {project.iDuree > 0 && (
                            <>
                              {" "}
                              soit{" "}
                              {Math.floor(
                                ((project.fBudget * structure.fPartCout) / 100 +
                                  budgetStructure) /
                                  project.iDuree
                              )}
                              &nbsp;€ par mois durant {project.iDuree} mois
                            </>
                          )}
                        </p>
                      )}
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {(isAdmin || isDirection) &&
            project.fBudget > 0 &&
            structure &&
            project.tStructure.find(
              (s: any) => s.iPKStructure === structure.iPKStructure
            ) && (
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="flex-col"
                  >
                    <Card>
                      <CardTitle>Votre décision sur ce projet</CardTitle>
                      {project.iType === 2 ? (
                        <>
                          {userVote && (
                            <div style={{ textAlign: "center" }}>
                              <br />
                              Votre vote :{" "}
                              <b
                                style={{
                                  color: userVote.bVote ? "#21ba45" : "red",
                                }}
                              >
                                {userVote.bVote ? "POUR" : "CONTRE"}
                              </b>
                              <br />
                              <br />
                              {/*!bChangeVote && (
                              <BtnOui onClick={() => setbChangeVote(true)}>
                                Changer mon vote
                              </BtnOui>
                            )*/}
                            </div>
                          )}
                          {!userVote && <br />}
                          {!userVote /*|| bChangeVote*/ && (
                            <VoteOptions>
                              <Button
                                style={{ width: 100 }}
                                positive
                                content="POUR"
                                disabled={disabled}
                                onClick={() => {
                                  setOpenModal(true);
                                  setBVote(true);
                                }}
                              />
                              <Button
                                style={{ width: 100 }}
                                content="CONTRE"
                                disabled={disabled}
                                onClick={() => {
                                  setOpenModal(true);
                                  setBVote(false);
                                }}
                              />
                            </VoteOptions>
                          )}
                          {userVote && userVote.bVote ? (
                            <p
                              style={{
                                width: "70%",
                                margin: "0 auto",
                                textAlign: "center",
                                marginTop: 20,
                              }}
                            >
                              <>
                                <label htmlFor="bPilote">
                                  Nous souhaitons participer avec ADS à la
                                  rédaction du Cahier des Besoins et la
                                  rédaction du Cahier des Charges qui sera
                                  présenté à l'ensemble des structures :
                                </label>
                                <br />
                                <br />
                                <div>
                                  <b>Non </b>
                                  <Radio
                                    style={{ marginBottom: -6 }}
                                    onClick={(e: any, data: any) =>
                                      insertbPilote(e, data)
                                    }
                                    id="bPilote"
                                    name="bPilote"
                                    checked={bPilote}
                                    toggle
                                  />{" "}
                                  <b>Oui</b>
                                </div>
                              </>
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {userVote ? (
                            <div>
                              <br />
                              Votre vote :{" "}
                              <b
                                style={{
                                  color: userVote.bVote ? "#21ba45" : "red",
                                }}
                              >
                                {userVote.bVote ? "POUR" : "CONTRE"}
                              </b>
                              <p>
                                Pilote du projet avec ADS :{" "}
                                <b
                                  style={{
                                    color: userVote.bPilote ? "#21ba45" : "red",
                                  }}
                                >
                                  {userVote.bPilote ? "Oui" : "Non"}
                                </b>
                              </p>
                            </div>
                          ) : (
                            <p>Vous n'avez pas voté pour ce projet.</p>
                          )}
                        </>
                      )}
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

          {false && (
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="flex-col"
                >
                  <Card>
                    <CardTitle>Demandes en cours</CardTitle>

                    <div>
                      <ScatterChart
                        width={640}
                        height={240}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 60,
                          left: 20,
                        }}
                      >
                        <XAxis
                          type="number"
                          dataKey={"x"}
                          name="Durée"
                          domain={[0, 5]}
                          ticks={[1, 2, 3, 4, 5]}
                        >
                          <Label
                            value="Durée"
                            offset={-20}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          type="number"
                          dataKey={"y"}
                          name="Intérêt"
                          domain={[0, 5]}
                          ticks={[1, 2, 3, 4, 5]}
                        >
                          <Label
                            value="Priorité"
                            offset={0}
                            position="insideLeft"
                            angle={-90}
                          />
                        </YAxis>
                        <ZAxis type="number" dataKey={"z"} name="Demande" />
                        <CartesianGrid width={0} height={0} />
                        <Tooltip cursor={{ strokeDasharray: "1 1" }} />
                        <Scatter name="Demandes" data={data} fill="#8884d8" />
                        <ReferenceLine x={2.5} stroke="green" label="" />
                        <ReferenceLine y={2.5} stroke="green" label="" />
                      </ScatterChart>
                    </div>
                    <RequestList project={project} isAdmin={isAdmin} />
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          <Grid>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="flex-col"
              >
                <Card>
                  <CardTitle>Agenda</CardTitle>

                  <EventList events={project.tEvenement} projects={[project]} />
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="flex-col"
              >
                <Card>
                  <CardTitle>Discussions</CardTitle>
                  <DiscussionList
                    discussions={project.tDiscussion}
                    projects={[project]}
                    events={project.tEvenement}
                  />
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Main>
        <Modal
          size="tiny"
          onClose={() => setOpenModal(false)}
          open={openModal}
          closeIcon
        >
          <Modal.Header>Confirmez votre vote</Modal.Header>
          <Modal.Content>
            <p>
              Etes-vous sûr de voter{" "}
              <b
                style={{
                  color: bVote ? "#21ba45" : "red",
                }}
              >
                {bVote ? "POUR" : "CONTRE"}
              </b>{" "}
              ce projet ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpenModal(false)}>Non</Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Oui"
              disabled={disabled}
              onClick={() => (userVote ? editVote(bVote) : insertVote(bVote))}
            />
          </Modal.Actions>
        </Modal>
        {editProjectModal ? (
          <ProjectForm
            title="Editer un projet"
            project={project}
            errors={{}}
            open={editProjectModal}
            onSave={onProjectEditClick}
            onClose={() => {
              setEditProjectModal(false);
            }}
          />
        ) : (
          <span />
        )}
      </StyledAnimatedView>
    )
  );
};

export default Project;
