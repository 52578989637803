import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { BouttonConnexion } from "./deconnexion.elements";

import { deconnexion } from "../../../../redux/utilisateur/utilisateur.slice";

import AnimatedView from "../../../core/layout/view/view.elements";

const DeconnexionView: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deconnexion());
  });

  return (
    <AnimatedView title="Déconnexion">
      <p>Vous êtes déconnecté !</p>

      <BouttonConnexion to="/connexion">Connexion</BouttonConnexion>


    </AnimatedView>
  );
};

export default DeconnexionView;
