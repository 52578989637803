import styled from "styled-components";

export const Main = styled.div`
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
  min-height: ${window.innerHeight - 114}px;
  padding: 0 20px 20px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    min-height: ${window.innerHeight - 116}px;
  }
`;
