import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Modal } from "semantic-ui-react";

import { ButtonAdd, ButtonDelete } from "../button/button.elements";

const BtnConfirm = styled(ButtonAdd)`
  margin: 0 0 0 15px;
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
`;

interface iProps {
  message: string;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Confirm: FunctionComponent<iProps> = ({
  message,
  onConfirm,
  onCancel,
}) => (
  <Modal size="mini" dimmer="inverted" open>
    <Modal.Header>Confirmation</Modal.Header>
    <Modal.Content>
      <p>{message}</p>
    </Modal.Content>
    <Modal.Actions>
      <BtnCancel onClick={onCancel}>Annuler</BtnCancel>
      <BtnConfirm onClick={onConfirm}>Confirmer</BtnConfirm>
    </Modal.Actions>
  </Modal>
);

Confirm.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Confirm;
