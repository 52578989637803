import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

interface Props {
  children: JSX.Element[] | JSX.Element | null;
  title: string;
}

interface ActionProps {
  action: string;
}

const View = styled(motion.main)`
  padding: 60px 10px 10px 10px;
`;

const AnimatedView = (props: Props): JSX.Element => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0, display: "none" },
  };

  return (
    <View
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      {...props}
    >
      {props.children}
    </View>
  );
};

export default AnimatedView;
