import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Utilisateur } from "../../../interfaces/utilisateur.interface";
import { voteCharteSucces, voteCharteErreur } from "../utilisateur.slice";
import axios from "axios";

export interface Response {
  voteCharte: {
    bVote: boolean;
  };
}

export const dispatchVoteCharte = (
  iFKUtilisateur: number,
  bVote: boolean
): ThunkAction<void, Utilisateur, unknown, Action<string>> => async (
  dispatch
) => {
  try {
    const response = await axios.post<Response>(
      `${process.env.REACT_APP_GRAPHQL_URL}/voteCharte`,
      {
        iFKUtilisateur,
        bVote,
      }
    );
    const data = response.data;

    dispatch(
      voteCharteSucces({
        bVote: data.voteCharte.bVote,
      })
    );
  } catch (err) {
    dispatch(voteCharteErreur({ sErreur: err }));
  }
};

export const getVoteCharte = (): ThunkAction<
  void,
  Utilisateur,
  unknown,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await axios.get<Response>(
      `${process.env.REACT_APP_GRAPHQL_URL}/voteCharte`
    );
    const data = response.data;

    dispatch(
      voteCharteSucces({
        bVote: data.voteCharte ? data.voteCharte.bVote : null,
      })
    );
  } catch (err) {
    dispatch(voteCharteErreur({ sErreur: err }));
  }
};
