import React, { FunctionComponent } from "react";

const CharteElement: FunctionComponent = () => {
  return (
    <>
      <p>
        Engagement contractuel pour le développement d’améliorations dans les
        logiciels proposés par ADS.
      </p>
      <h2>Préambule : </h2>
      <p>
        L’augmentation du nombre de clients et les disparités de tailles rendent
        aujourd’hui obsolètes les méthodes de mutualisation des développements,
        avec des difficultés de prises de décisions pouvant freiner les projets
        et le bon développement de ces applications.
      </p>
      <p>
        Considérant ces éléments, nous avons décidé de créer un club utilisateur
        ADS dont les règles de fonctionnement vous sont proposées ci-après.
      </p>
      <h2>Objet :</h2>
      <p>Validation et mutualisation des projets ADS</p>
      <p>
        ADS développe des applications (Is@dom et applications annexes) à la
        demande de ses clients. Ces développements sont issus de demandes
        d’améliorations, soumises à devis, et permettent d’enrichir le produit
        durant tout son cycle d’utilisation, ou la création de nouveaux
        produits.
      </p>
      <p>
        Le présent document a pour but de contractualiser une méthode de
        validation des demandes les plus importantes, aboutissant à un devis
        d’un minimum de 10 jours (hors spécifique).
      </p>
      <p>
        ADS a créé un portail internet avec un accès privatif pour les
        dirigeants ou responsables de ses structures clientes.
      </p>
      <p>
        Cet accès permettra à la société ADS de présenter les projets de
        développement pour améliorer ses produits, qu’ils soient de sa propre
        initiative ou à la demande d’un ou plusieurs clients. Cela inclura un
        cahier des charges présentant les fonctionnalités attendues, permettant
        aux clients d’évaluer la pertinence de ces projets pour leurs
        structures.
      </p>
      <p>
        Un système de vote sera mis en place, permettant à chacun de voter pour
        ou contre un projet de développement.
      </p>
      <p>
        Une date limite de vote sera fixée. Le résultat du vote sera établi sur
        les structures qui auront participé dans les délais. Les structures qui
        n’auront pas voté devront se soumettre au résultat.
      </p>

      <h2>Valorisations :</h2>
      <p>
        <b>
          Le poids de chaque client pour le vote ainsi que pour le financement
          sera proportionnel au nombre d’utilisateurs actifs dans sa structure.
        </b>
        <br />A ce stade, une évaluation du coût a été réalisée par client.
      </p>
      <p>
        Votre participation pour l’année 2020 serait de ….% sur la base du
        nombre d’utilisateurs 2019. Ce taux sera réévalué tous les ans, incluant
        les éventuels nouveaux clients venant diminuer la participation de
        chacun ainsi que tous les cas ayant un impact sur le nombre
        d’utilisateurs de chaque structure.
      </p>
      <p>
        L’avantage de cette méthode est double : Elle permet de limiter de
        manière importante les investissements pour les petites structures. La
        part des structures plus importantes sera majorée, mais compensée par la
        participation systématique de tous les clients à tous les projets
        retenus, ce qui limitera leur coût sur des projets qu’elles auraient été
        amenées à financer seules ou avec une mutualisation sur un nombre moins
        important de participants.
      </p>
      <p>
        De plus, les décisions seront plus rapides, la réactivité d’ADS aux
        demandes en sera améliorée.
      </p>
      <p>
        La même méthode de facturation proportionnelle à la taille de chaque
        client s’appliquera également aux modifications réglementaires.
      </p>

      <h2>A l’issue de ce vote :</h2>
      <ul>
        <li>
          <b>
            Le projet a recueilli 50% ou + des voix, et comprenant minimum 3
            structures de groupes différents :
          </b>
          <p>
            Le projet est validé. Tous les clients d’ADS participeront au
            financement de ce projet, quelle que soit la nature de leur vote
            (Proportionnellement à leur taille comme indiqué ci-dessus).{" "}
          </p>
          <p>
            Toutefois, si le projet était redondant avec un développement déjà
            financé et existant chez un client en spécifique, alors cette
            structure ne participera pas au financement. De même, si cette
            amélioration entrait en contradiction technique ou métier avec des
            solutions existantes ou à venir dans une structure, celle-ci pourra
            en être exemptée.
          </p>
        </li>
      </ul>

      <ul>
        <li>
          <b>Le projet a recueilli moins de 50% des voix :</b>
          <p>
            Le projet n’est pas validé et ne sera donc pas mutualisé sur
            l’ensemble des clients. Le projet pourra néanmoins être développé en
            spécifique. Si une structure ne participant pas à ce financement
            souhaite activer le module ultérieurement, elle sera redevable d’un
            montant égal à la participation initiale la plus élevée.
          </p>
        </li>
      </ul>

      <p>
        Le montant total des développements acceptés par vote (hors
        réglementaire qui reste obligatoire) ne pourra excéder la somme de 300
        000€ annuel, sauf décision exceptionnelle du club utilisateur à
        l'unanimité. Le budget maximal des développements mutualisés sur une
        année est ainsi connu à l’avance pour chacune des structures.
      </p>
      <br />
    </>
  );
};

export default CharteElement;
