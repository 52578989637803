import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";

import { Modal, Form, Input, TextArea, Select } from "semantic-ui-react";

import { DatePickerInput } from "rc-datepicker";

import "../../../../../ui/assets/css/datepicker.css";
import "moment/locale/fr";

import {
  ButtonAdd,
  ButtonDelete,
} from "../../../../core/layout/button/button.elements";

const BtnSave = styled(ButtonAdd)`
  padding: 0 20px;
  height: 40px;
`;
const BtnCancel = styled(ButtonDelete)`
  padding: 0 20px;
  height: 40px;
  margin-right: 10px;
`;

class EventAddForm extends Component {
  state = {
    iPKEvent: 0,
    sLibelle: "",
    sInformation: "",
    sCompteRendu: "",
  };

  constructor(props) {
    super(props);

    const { event, projects } = this.props;

    if (event) {
      this.state = {
        iPKEvenement: event.iPKEvenement,
        sLibelle: event.sLibelle,
        sInformation: event.sInformation,
        sCompteRendu: event.sCompteRendu,
        sLieu: event.sLieu,
        dDebut: event.dDebut,
        dFin: event.dFin,
        date: DateTime.fromISO(event.dDebut).toFormat("yyyy-LL-dd"),
        start: DateTime.fromISO(event.dDebut).toFormat("HH:mm"),
        end: DateTime.fromISO(event.dFin).toFormat("HH:mm"),
        iFKProjet: event.Projet.iPKProjet,
      };
    } else if (projects && projects.length === 1) {
      this.state = {
        iFKProjet: projects[0].iPKProjet,
      };
    }
  }

  onFieldChange = (evt, data) => {
    this.setState({ [data.id]: data.value });
  };

  onDateChange = (date) => {
    this.setState({
      date: date !== null ? DateTime.fromJSDate(date).toISODate() : null,
    });
  };

  onHourChange = (evt, data) => {
    let { value } = data;
    if (data.value.length >= 2 && data.value[1] === ":") {
      value = `0${value}`;
    }
    if (data.value.length === 4 && data.value.indexOf(":") === -1) {
      value = `${data.value[0]}${data.value[1]}:${data.value[2]}${data.value[3]}`;
    }

    this.setState({
      [data.id]: value,
    });
  };

  onFocus = (evt) => {
    evt.target.setSelectionRange(0, evt.target.value.length);
  };

  onBlur = (evt) => {
    const el = evt.target;

    if (el.value.length === 2) {
      this.onHourChange(evt, { id: el.id, value: `${el.value}:00` });
    } else if (el.value.length < 5 || el.value[2] !== ":") {
      this.setState((prevState) => ({
        errors: Object.assign({}, prevState.errors, {
          [el.id]: "format incorrect",
        }),
      }));
    }
  };

  render() {
    const { title, open, onClose, onSave, projects, errors } = this.props;
    const {
      sLibelle,
      sInformation,
      sCompteRendu,
      sLieu,
      date,
      start,
      end,
      iFKProjet,
    } = this.state;

    return (
      <Modal
        size="small"
        centered={false}
        dimmer="inverted"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={4} error={!!errors.date}>
                <label htmlFor="dDebut">Date</label>
                <DatePickerInput
                  onChange={this.onDateChange}
                  value={date}
                  locale="fr"
                  id="date"
                  displayFormat="DD/MM/YYYY"
                />
              </Form.Field>
              <Form.Field
                id="start"
                control={Input}
                label="Début"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onHourChange}
                value={start}
                placeholder="Heure de début"
                width={2}
                error={!!errors.start}
              />
              <Form.Field
                id="end"
                control={Input}
                label="Fin"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onHourChange}
                value={end}
                placeholder="Heure de début"
                width={2}
                error={!!errors.end}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                id="iFKProjet"
                control={Select}
                options={projects.map((x) => {
                  return {
                    key: x.iPKProjet,
                    text: x.sLibelle,
                    value: x.iPKProjet,
                  };
                })}
                label="Projet"
                placeholder="Choisissez dans la liste"
                search
                onChange={this.onFieldChange}
                onSearchChange={this.onFieldChange}
                searchInput={{ id: "iFKProjet" }}
                value={iFKProjet}
                error={!!errors.iFKProjet}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                width={16}
                id="sLibelle"
                label="Libelle"
                value={sLibelle}
                onChange={this.onFieldChange}
                error={!!errors.sLibelle}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                width={16}
                id="sLieu"
                label="Lieu"
                value={sLieu}
                onChange={this.onFieldChange}
                error={!!errors.sLieu}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={TextArea}
                width={16}
                id="sInformation"
                label="Information"
                value={sInformation}
                onChange={this.onFieldChange}
                error={!!errors.sInformation}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={TextArea}
                width={16}
                id="sCompteRendu"
                label="Compte rendu"
                value={sCompteRendu}
                onChange={this.onFieldChange}
                error={!!errors.sCompteRendu}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <BtnCancel onClick={onClose}>Annuler</BtnCancel>
          <BtnSave onClick={(evt) => onSave(evt, this.state)}>
            Enregistrer
          </BtnSave>
        </Modal.Actions>
      </Modal>
    );
  }
}

EventAddForm.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  event: PropTypes.objectOf(PropTypes.any),
  projects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

EventAddForm.defaultProps = {
  event: null,
};

export default EventAddForm;
