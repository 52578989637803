import React, { Component } from "react";
import PropTypes from "prop-types";

import * as Table from "../../../core/layout/view/table.elements";

import Confirm from "../../../core/layout/view/confirm.elements";
import {
  ButtonAdd,
  ButtonEdit,
  ButtonDelete,
} from "../../../core/layout/button/button.elements";

import RequestForm from "./Form/Request";

class RequestList extends Component {
  state = {
    addRequestModal: false,
    editRequestModal: false,
    deleteRequestModal: false,
    request: null,
  };

  iDureeOptions = [
    { text: "< 1 mois", value: 1 },
    { text: "< 3 mois", value: 2 },
    { text: "< 6 mois", value: 3 },
    { text: "< 1 an", value: 4 },
    { text: "< 2 ans", value: 5 },
  ];

  onBtnAddClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      addRequestModal: true,
      editRequestModal: false,
      deleteRequestModal: false,
    });
  };

  onRequestAddClick = (evt, request) => {
    evt.preventDefault();

    const { dispatchRequestAdd, project } = this.props;

    dispatchRequestAdd(request, project);

    this.onCloseRequestAddForm();
  };

  onCloseRequestAddForm = () => {
    this.setState({
      addRequestModal: false,
      editRequestModal: false,
      deleteRequestModal: false,
      request: null,
    });
  };

  onBtnEditClick = (evt, request) => {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      addRequestModal: false,
      editRequestModal: true,
      deleteRequestModal: false,
      request,
    });
  };

  onRequestEditClick = (evt, request) => {
    evt.preventDefault();

    const { dispatchRequestEdit, project } = this.props;

    dispatchRequestEdit(request, project);

    this.onCloseRequestEditForm();
  };

  onCloseRequestEditForm = () => {
    this.setState({
      addRequestModal: false,
      editRequestModal: false,
      deleteRequestModal: false,
      request: null,
    });
  };

  onBtnDeleteClick = (evt, request) => {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      addRequestModal: false,
      editRequestModal: false,
      deleteRequestModal: true,
      request,
    });
  };

  onRequestDeleteClick = (evt, request) => {
    evt.preventDefault();

    const { dispatchRequestDelete, project } = this.props;

    dispatchRequestDelete(request, project);

    this.onCloseRequestEditForm();
  };

  onCloseRequestDeleteForm = () => {
    this.setState({
      addRequestModal: false,
      editRequestModal: false,
      deleteRequestModal: false,
      request: null,
    });
  };

  render() {
    const { project, isAdmin } = this.props;

    const {
      addRequestModal,
      editRequestModal,
      deleteRequestModal,
      request,
    } = this.state;

    const { tDemande } = project;

    return (
      <>
        {tDemande && (
          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Libellé</Table.Th>
                <Table.Th>Priorité</Table.Th>
                <Table.Th>Durée</Table.Th>
                {isAdmin && <Table.ThActions>Actions</Table.ThActions>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {tDemande.map((r) => (
                <Table.Tr key={r.iPKDemande}>
                  <Table.Td>
                    <Table.TdLink to={`/agenda/${r.iPKDemande}/${r.sLibelle}`}>
                      {r.sLibelle}
                    </Table.TdLink>
                  </Table.Td>
                  <Table.Td>
                    <Table.TdLink to={`/agenda/${r.iPKDemande}/${r.sLibelle}`}>
                      {r.iPriorite}
                    </Table.TdLink>
                  </Table.Td>
                  <Table.Td>
                    <Table.TdLink to={`/agenda/${r.iPKDemande}/${r.iDuree}`}>
                      {
                        this.iDureeOptions.filter(
                          (d) => d.value === r.iDuree
                        )[0].text
                      }
                    </Table.TdLink>
                  </Table.Td>
                  {isAdmin && (
                    <Table.TdActions>
                      <ButtonEdit
                        onClick={(evt) =>
                          this.onBtnEditClick(evt, r, project.iPKProjet)
                        }
                      >
                        Editer
                      </ButtonEdit>
                      <ButtonDelete
                        onClick={(evt) => this.onBtnDeleteClick(evt, r)}
                      >
                        Supprimer
                      </ButtonDelete>
                    </Table.TdActions>
                  )}
                </Table.Tr>
              ))}
              {isAdmin && (
                <Table.TrActions>
                  <Table.Td colSpan="4">
                    <ButtonAdd onClick={this.onBtnAddClick}>
                      Ajouter une demande
                    </ButtonAdd>
                  </Table.Td>
                </Table.TrActions>
              )}
            </Table.Tbody>
          </Table.Table>
        )}

        {addRequestModal && (
          <RequestForm
            title="Ajouter une demande"
            request={request}
            project={project}
            open={addRequestModal}
            onSave={this.onRequestAddClick}
            onClose={this.onCloseRequestAddForm}
          />
        )}

        {editRequestModal && (
          <RequestForm
            title="Editer une demande"
            request={request}
            project={project}
            open={editRequestModal}
            onSave={this.onRequestEditClick}
            onClose={this.onCloseRequestEditForm}
          />
        )}

        {deleteRequestModal && (
          <Confirm
            message="Souhaitez-vous supprimer cette demande ?"
            request={request}
            open={editRequestModal}
            onConfirm={(evt) => this.onRequestDeleteClick(evt, request)}
            onCancel={this.onCloseRequestDeleteForm}
          />
        )}
      </>
    );
  }
}

RequestList.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  dispatchRequestAdd: PropTypes.func.isRequired,
  dispatchRequestEdit: PropTypes.func.isRequired,
  dispatchRequestDelete: PropTypes.func.isRequired,
};

export default RequestList;
