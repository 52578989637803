import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Utilisateur } from "../../../interfaces/utilisateur.interface";
import { connexionSucces, connexionErreur } from "../utilisateur.slice";
import axios from "axios";

export interface Response {
  user: {
    iPKUtilisateur: string;
    sIdentifiant: string;
    iRole: number;
    iFKStructure: number;
    tVote: any[];
  };
  cookie: string;
}

export const connexion = (
  sIdentifiant: string,
  sMotDePasse: string
): ThunkAction<void, Utilisateur, unknown, Action<string>> => async (
  dispatch
) => {
  try {
    const response = await axios.post<Response>(
      `${process.env.REACT_APP_GRAPHQL_URL}/auth/login`,
      {
        sIdentifiant,
        sMotDePasse,
      }
    );
    const data = response.data;
    document.cookie = data.cookie;

    dispatch(
      connexionSucces({
        iPKUtilisateur: Number(data.user.iPKUtilisateur),
        sIdentifiant: data.user.sIdentifiant,
        iFKStructure: data.user.iFKStructure,
        iRole: data.user.iRole,
        tVote: data.user.tVote,
      })
    );
  } catch (err) {
    dispatch(connexionErreur({ sErreur: err }));
  }
};
