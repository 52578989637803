import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import axios from "axios";

import AnimatedView from "../../core/layout/view/view.elements";
import { Main } from "../../core/layout/view/main.elements";
import Discussion from "./elements/discussion.element";

import { Title } from "../../core/layout/view/title.elements";
import {
  ListeQuestion,
  ConteneurQuestion,
  QuestionContenu,
} from "./discussion.elements";

import {
  DiscussionCharte,
  QuestionCharteResultat,
  ReponseCharteResultat,
} from "../../../interfaces/questionCharte.interface";

interface Reponse {
  discussion: DiscussionCharte;
}

const DiscussionCharteView: FunctionComponent = () => {
  const [discussion, setDiscussion] = useState<DiscussionCharte>({
    tQuestion: [],
    tReponse: [],
    sErreur: "",
  });
  const [bNouvelleReponse, setNouvelleReponse] = useState<boolean>(false);
  useEffect(() => {
    const getDiscussion = async () => {
      const response = await axios.get<Reponse>(
        `${process.env.REACT_APP_GRAPHQL_URL}/charte/getDiscussion`
      );
      setDiscussion(response.data.discussion);
    };
    getDiscussion();
  }, [bNouvelleReponse]);

  return (
    <AnimatedView title="Discussion autour de la charte">
      <Main>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title>
                Discussion autour de la charte du club utilisateur ADS
              </Title>
              <ListeQuestion>
                {discussion.tQuestion.length > 0 ? (
                  discussion.tQuestion.map((q: QuestionCharteResultat) => (
                    <Discussion
                      iPKQuestionCharte={q.iPKQuestionCharte}
                      sIdentifiant={q.sIdentifiant}
                      sQuestion={q.sQuestion}
                      tReponse={discussion.tReponse.filter(
                        (r: ReponseCharteResultat) =>
                          r.iFKQuestionCharte === q.iPKQuestionCharte
                      )}
                      setNouvelleReponse={setNouvelleReponse}
                    />
                  ))
                ) : (
                  <ConteneurQuestion>
                    <QuestionContenu>
                      Aucune discussion n'existe pour le moment
                    </QuestionContenu>
                  </ConteneurQuestion>
                )}
              </ListeQuestion>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Main>
    </AnimatedView>
  );
};

export default DiscussionCharteView;
