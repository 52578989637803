import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";

const initialState = {
  tEvenement: [],
  sErreur: "",
};

export const slice = createSlice({
  name: "evenement",
  initialState,
  reducers: {
    evenementSucces: (state, { payload }) => {
      state.tEvenement = payload;
      state.sErreur = "";
    },
    evenementErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.sErreur = "Une erreur est survenue";
    },
  },
});

export const { evenementSucces, evenementErreur } = slice.actions;
export const selectEvenement = (state: Store) => state.evenement.tEvenement;
export const selectStoreErreur = (state: Store) => state.evenement.sErreur;

export default slice.reducer;
