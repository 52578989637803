import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { selectConnecte } from "../../../../../redux/utilisateur/utilisateur.slice";

import { H1 } from "../../../../core/layout/view/title.elements";
import {
  StyledHero,
  ContainerWrapper,
  Introduction,
  BtnLogin,
} from "./hero.elements";

export const Hero: FunctionComponent = () => {
  const bConnecte = useSelector(selectConnecte);

  return (
    <StyledHero>
      <ContainerWrapper>
        <H1>
          Bienvenue sur <br />
          l&apos;Espace des Utilisateurs ADS
        </H1>

        {bConnecte ? (
          <Introduction>
            <Link to="/projets">
              <BtnLogin>Accéder aux projets.</BtnLogin>
            </Link>
          </Introduction>
        ) : (
          <>
            <Introduction>
              Pour accéder à vos solutions en ligne, identifiez-vous.
            </Introduction>
            <Link to="/connexion">
              <BtnLogin>Identifiez-vous</BtnLogin>
            </Link>
          </>
        )}
      </ContainerWrapper>
    </StyledHero>
  );
};
