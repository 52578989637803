import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { selectUtilisateur } from "../../../../../redux/utilisateur/utilisateur.slice";
// import { listEvents } from "../../../redux/actions/event";
// import { listProjects } from "../../../redux/actions/project";
/* import {
  listDiscussions,
  addDiscussion,
  editDiscussion,
  deleteDiscussion
} from "../../../redux/actions/discussion";*/
import * as Table from "../../../../core/layout/view/table.elements";
import Confirm from "../../../../core/layout/view/confirm.elements";

import MessageList from "../../../message/elements/List";

import DiscussionForm from "../Form";

import {
  ButtonAdd,
  ButtonDelete,
  ButtonEdit,
} from "../../../../core/layout/button/button.elements";

import { Discussion } from "../../../../../interfaces/projet.interface";

interface Reponse {
  discussion: Discussion;
}

interface Props {
  discussions: any[];
  events?: any[];
  projects?: any[];
}

const DiscussionsList: FunctionComponent<Props> = ({
  discussions,
  events,
  projects,
}: Props) => {
  const [addDiscussionModal, setAddDiscussionModal] = useState<boolean>(false);
  const [editDiscussionModal, setEditDiscussionModal] = useState<boolean>(
    false
  );
  const [deleteDiscussionModal, setDeleteDiscussionModal] = useState<boolean>(
    false
  );
  const [displayMessageList, setDisplayMessageList] = useState<any>({});
  const [discussion, setDiscussion] = useState<any>({});
  const [tDiscussion, setDiscussions] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const utilisateur = useSelector(selectUtilisateur);

  const isAdmin = utilisateur.identite.iRole === 4;

  useEffect(() => {
    setDiscussions(discussions);
    if (discussions && discussions.length > 0) {
      setDisplayMessageList({ [discussions[0].iPKDiscussion]: true });
    }
  }, [discussions]);

  const onCloseDiscussionAddForm = () => {
    setAddDiscussionModal(false);
    setEditDiscussionModal(false);
    setDeleteDiscussionModal(false);
    setDiscussion(null);
  };

  const onCloseDiscussionEditForm = () => {
    setAddDiscussionModal(false);
    setEditDiscussionModal(false);
    setDeleteDiscussionModal(false);
    setDiscussion(null);
  };

  const onCloseDiscussionDeleteForm = () => {
    setAddDiscussionModal(false);
    setEditDiscussionModal(false);
    setDeleteDiscussionModal(false);
    setDiscussion(null);
  };

  const onBtnAddClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddDiscussionModal(true);
    setEditDiscussionModal(false);
    setDeleteDiscussionModal(false);
  };

  const onDiscussionAddClick = async (evt: any, discussion: any) => {
    evt.preventDefault();

    const { sLibelle, sDescription, iFKProjet } = discussion;

    let errors: any = {};

    if (!iFKProjet) {
      errors.iFKProjet = true;
    }
    if (!sLibelle) {
      errors.sLibelle = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const response = await axios.post<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/discussion`,
      {
        sLibelle,
        sDescription,
        iFKProjet,
        iFKUtilisateur: utilisateur.identite.iPKUtilisateur,
      }
    );
    if (response.data.discussion) {
      tDiscussion.push(response.data.discussion);
      setDiscussions(tDiscussion);
      onCloseDiscussionAddForm();
    }
  };

  const onBtnEditClick = (evt: any, discussion: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddDiscussionModal(false);
    setEditDiscussionModal(true);
    setDeleteDiscussionModal(false);
    setDiscussion(discussion);
  };

  const onDiscussionEditClick = async (evt: any, discussion: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { iPKDiscussion, sLibelle, sDescription, iFKProjet } = discussion;
    let errors: any = {};

    if (!iFKProjet) {
      errors.iFKProjet = true;
    }
    if (!sLibelle) {
      errors.sLibelle = true;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const response = await axios.put<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/discussion`,
      {
        iPKDiscussion,
        sLibelle,
        sDescription,
        iFKProjet,
      }
    );
    if (response.data.discussion) {
      setDiscussions(
        tDiscussion.map((d: any) =>
          d.iPKDiscussion === response.data.discussion.iPKDiscussion
            ? response.data.discussion
            : d
        )
      );
      onCloseDiscussionAddForm();
    }

    onCloseDiscussionEditForm();
  };

  const onBtnDeleteClick = (evt: any, discussion: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setAddDiscussionModal(false);
    setEditDiscussionModal(false);
    setDeleteDiscussionModal(true);
    setDiscussion(discussion);
  };

  const onDiscussionDeleteClick = async (evt: any, discussion: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    const { iPKDiscussion } = discussion;
    const response = await axios.delete<Reponse>(
      `${process.env.REACT_APP_GRAPHQL_URL}/discussion`,
      {
        data: { iPKDiscussion },
      }
    );
    if (response.data.discussion) {
      setDiscussions(
        tDiscussion.filter(
          (d: any) => d.iPKDiscussion !== response.data.discussion.iPKDiscussion
        )
      );
      onCloseDiscussionEditForm();
    }
  };

  const toggleDisplayMessageList = (evt: any, iPKDiscussion: any) => {
    setDisplayMessageList({
      [iPKDiscussion]: !displayMessageList[iPKDiscussion],
    });
  };

  return (
    <>
      {tDiscussion && tDiscussion.length > 0 ? (
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Libelle</Table.Th>
              <Table.Th>Description</Table.Th>
              {isAdmin && <Table.ThActions>Actions</Table.ThActions>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {tDiscussion.map((d: any) => (
              <>
                <Table.Tr key={d.iPKDiscussion}>
                  <Table.Td style={{ minWidth: 150 }}>
                    <Table.TdLink
                      to="#"
                      onClick={(evt) =>
                        toggleDisplayMessageList(evt, d.iPKDiscussion)
                      }
                    >
                      {d.sLibelle}
                    </Table.TdLink>
                  </Table.Td>
                  <Table.Td>
                    <Table.TdLink
                      to="#"
                      onClick={(evt) =>
                        toggleDisplayMessageList(evt, d.iPKDiscussion)
                      }
                    >
                      {d.sDescription}
                    </Table.TdLink>
                  </Table.Td>
                  {isAdmin && (
                    <Table.TdActions>
                      <ButtonEdit onClick={(evt) => onBtnEditClick(evt, d)}>
                        Editer
                      </ButtonEdit>
                      <ButtonDelete onClick={(evt) => onBtnDeleteClick(evt, d)}>
                        Supprimer
                      </ButtonDelete>
                    </Table.TdActions>
                  )}
                </Table.Tr>
                {!!displayMessageList[d.iPKDiscussion] && (
                  <Table.Tr key={`messages-${d.iPKDiscussion}`}>
                    <Table.Td colSpan={isAdmin ? 3 : 2}>
                      {" "}
                      <MessageList
                        display={!!displayMessageList[d.iPKDiscussion]}
                        iFKDiscussion={d.iPKDiscussion}
                        messages={d.tMessage}
                      />
                    </Table.Td>
                  </Table.Tr>
                )}
              </>
            ))}
          </Table.Tbody>
        </Table.Table>
      ) : (
        <p>Aucune discussion...</p>
      )}
      {isAdmin && (
        <ButtonAdd style={{ marginTop: 10 }} onClick={onBtnAddClick}>
          Ajouter une discussion
        </ButtonAdd>
      )}
      {addDiscussionModal && (
        <DiscussionForm
          title="Ajouter une discussion"
          open={addDiscussionModal}
          errors={errors}
          projects={projects}
          onSave={onDiscussionAddClick}
          onClose={onCloseDiscussionAddForm}
        />
      )}

      {editDiscussionModal && (
        <DiscussionForm
          title="Editer une discussion"
          discussion={discussion}
          errors={errors}
          projects={projects}
          open={editDiscussionModal}
          onSave={onDiscussionEditClick}
          onClose={onCloseDiscussionEditForm}
        />
      )}

      {deleteDiscussionModal && (
        <Confirm
          message="Souhaitez-vous supprimer cette discussion ainsi que tous ses messages ?"
          onConfirm={(evt) => onDiscussionDeleteClick(evt, discussion)}
          onCancel={onCloseDiscussionDeleteForm}
        />
      )}
    </>
  );
};

export default DiscussionsList;
